<template>
    <div>
        <div v-if="hasMobile" @click="callMobile">
            <template-stylebox-item-view :config="config"></template-stylebox-item-view>
        </div>
        <div v-else-if="config.linkType === 'warranty'" @click="openWarrent">
            <template-stylebox-item-view :config="config"></template-stylebox-item-view>
        </div>
        <template v-else-if="config.linkType === 'weixinWeapp'">
            <div v-if="inWeixin" v-html="createHtml(config)"></div>
            <div v-else @click="showAlert('请在微信中打开')">
                <template-stylebox-item-view :config="config"></template-stylebox-item-view>
            </div>
        </template>
        <div v-else-if="shouldPop" @click="showImage">
            <template-stylebox-item-view :config="config"></template-stylebox-item-view>
            <el-dialog
                title="请长按关注微信公众号"
                :visible.sync="dialogVisible"
                :fullscreen="true"
                :show-close="false"
            >
                <span><img :src="config.qrCodeImage"/></span>
                <span slot="footer" class="dialog-footer">
                    <div style="width: 100%; text-align: center">
                        <el-button @click="closeImage">关闭</el-button>
                    </div>
                </span>
            </el-dialog>
        </div>
        <a v-else-if="hasHref" :href="href" target="_self" style="text-decoration: none">
            <template-stylebox-item-view :config="config"></template-stylebox-item-view>
        </a>
        <div v-else>
            <template-stylebox-item-view :config="config"></template-stylebox-item-view>
        </div>
    </div>
</template>

<script>
import TemplateStyleboxItemView from './TemplateStyleboxItemView'

export default {
    props: {
        config: Object,
        code: Object
    },
    data() {
        return {
            dialogVisible: false,
            testImage: require('../../assets/img/product-image-1.png')
        }
    },
    computed: {
        hasHref() {
            return !!this.config.href
        },
        hasMobile() {
            return !!this.config.kefuMobile
        },
        getMobile() {
            return this.config.kefuMobile
        },
        shouldPop() {
            return !!this.config.qrCodeImage
        },
        href() {
            return this.config.href || '#'
        },
        inWeixin() {
            return /MicroMessenger/i.test(navigator.userAgent)
        }
    },
    mounted() {
        this.initLocale()
    },
    methods: {
        initLocale() {
            // var locale = sessionStorage.getItem('locale')
            // if (locale == null || locale == '') {
            //     sessionStorage.setItem('locale', 'cn')
            // }
            // this.$i18n.locale = sessionStorage.getItem('locale')
        },
        imageSrc(src) {
            return src + '?x-image-process=style/width-1024'
        },
        showImage() {
            this.dialogVisible = true
        },
        async openWarrent() {
            window.location.href = this.config.href
        },
        callMobile() {
            window.location.href = 'tel://' + this.config.kefuMobile
        },
        closeImage(e) {
            this.dialogVisible = false
            e.stopPropagation()
        },
        showAlert(msg) {
            alert(msg)
        },
        createHtml(config) {
            let html = '<div class="stylebox-item" style="'
            if (config.backimage) {
                html += `background-image: url(${this.imageSrc(config.backimage)})`
            } else {
                html += `background-color: ${config.backcolor}`
            }
            html += '">'
            // if (config.fonticon) {
            //     html += `<i class="${config.fonticon} image" style="color: ${config.forecolor}"></i>`
            // }
            if (config.imgIcon) {
                html += `<img src="${config.imgIcon}" class="imgIcon" />`
            }
            if (config.title) {
                html += `<div class="title" style="text-align: ${config.titleAlign}; color: ${config.forecolor}">${config.title}</div>`
            }
            html += `</div>`
            html = `
            <template>
                <style>
                .stylebox-item {
                    margin: 2px;
                    height: 100px !important;
                    color: #ffffff;
                    width: 100%;

                    background-color: #ffffff;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;

                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                }
                .image {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    font-size: 40px;
                    line-height: 40px;
                }
                .title {
                    margin: 2px 0 0 0;
                }
                .imgIcon {
                    border-radius: 50%;
                    width: 70px;
                    height: 70px;
                    display: block;
                }
                </style>
                ${html}
            </template>`

            return `
            <wx-open-launch-weapp
                username="${config.weixinWeappUserName}"
                path="${config.weixinWeappPath}"
                style="width: ${config.size * 33 - 1}vw; height: 100px;"
            >
                ${html}
            </wx-open-launch-weapp>
            `
        }
    },
    components: {
        TemplateStyleboxItemView
    }
}
</script>
