import eventBus from './event-bus'
import * as server from './server'

export function errorHint(error) {
    let message
    if (error.message) {
        message = error.message
    } else {
        message = error.statusText
    }
    eventBus.$emit('showMessage', {
        showClose: true,
        type: 'error',
        message
    })
}

export function wrapResult(promise) {
    return promise.catch(e => {
        errorHint(e)
        throw e
    })
}

export function wrapJsonResult(promise) {
    return wrapResult(promise).then(data => {
        if (!data.code) {
            return Promise.resolve(data)
        } else if (data.code !== 200) {
            errorHint(data)
            return Promise.reject(data)
        } else {
            return Promise.resolve(data.data)
        }
    })
}

export function getJsonResult(url, arg = {}) {
    return wrapJsonResult(server.getJson(url, arg))
}
