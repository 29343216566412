<template>
    <div class="mc-wrap f14">
        <a v-if="item.value.images.length" :href="href" target="_blank">
            <img
                v-for="(t, index) in item.value.images"
                :key="index"
                :src="imageSrc(t)"
                style="width: 100%; display: block;"
            />
        </a>
        <template v-else>
            <p class="weixin-empty-hint">(无图片)</p>
        </template>
    </div>
</template>

<script>
import '../../assets/css/weixin.scss'

export default {
    props: {
        item: Object
    },
    methods: {
        imageSrc(src) {
            return src + '?x-image-process=style/width-1024'
        }
    },
    computed: {
        href() {
            return this.item.value.link
        }
    }
}
</script>

<style lang="scss" scoped>
.mc-wrap {
    padding: 0px !important;
    // margin-top: 10px;
}
.weixin-empty-hint {
    height: 100px;
}
</style>
