<template>
    <div class="home-msg">
        <div class="home-msg-cont">
            <div v-for="item in template.items" :key="item.id">
                <template-head v-if="item.type === 'templateHead'" :item="item"></template-head>
                <template-carousel v-if="item.type === 'carousel'" :item="item"></template-carousel>
                <product-desc v-if="item.type === 'productIntroduction'" :product="item.ad.product"></product-desc>
                <related-product v-if="item.type === 'relatedProduct'" :list="item.ad.list"></related-product>
                <template-text v-if="item.type === 'text'" :item="item"></template-text>
                <template-video v-if="item.type === 'video'" :item="item"></template-video>
                <template-pictures v-if="item.type === 'pictures'" :item="item"></template-pictures>
                <product-info
                    v-if="item.type === 'productInfo'"
                    :item="item"
                    :code="item.ad.code"
                    :code-attrs="item.ad.codeAttrs"
                    :batch="item.ad.batch"
                ></product-info>
                <template-stylebox v-if="item.type === 'stylebox'" :item="item" :code="code"></template-stylebox>
                <code-information
                    v-if="item.type === 'codeInformation'"
                    :item="item"
                    :code="item.ad.code"
                ></code-information>
                <product-barcode v-if="item.type === 'productBarcode'" :item="item" :code="code"></product-barcode>
                <product-images
                    v-if="item.type === 'productImages' && item.ad.product.images.length > 0"
                    :item="item"
                    :images="item.ad.product.images"
                ></product-images>
                <product-price v-if="item.type === 'productPrice'" :item="item" :code="item.ad.code"></product-price>
                <product-quality-guarantee-period
                    v-if="item.type === 'productQualityGuaranteePeriod'"
                    :item="item"
                    :code="item.ad.code"
                    :batch="item.ad.batch"
                ></product-quality-guarantee-period>
                <three-img v-if="item.type === 'threeImg'" :item="item"></three-img>
            </div>
        </div>
    </div>
</template>

<script>
const defaultIcon = require('../../assets/img/logo.png')

import { getJsonResult } from '../../utils/server-wrapper'

import CONFIG from '../../components/code-info-item/config'
import RelatedProduct from '../../components/code-info-item/RelatedProduct'
import ProductDesc from '../../components/code-info-item/ProductDesc'
import ProductInfo from '../../components/code-info-item/ProductInfo'
import TemplatePictures from '../../components/code-info-item/TemplatePictures'
import TemplateVideo from '../../components/code-info-item/TemplateVideo'
import TemplateStylebox from '../../components/code-info-item/TemplateStylebox'
import TemplateText from '../../components/code-info-item/TemplateText'
import TemplateHead from '../../components/code-info-item/TemplateHead'
import TemplateCarousel from '../../components/code-info-item/TemplateCarousel'
import CodeInformation from '../../components/code-info-item/CodeInformation'
import ProductImages from '../../components/code-info-item/ProductImages'
import ProductBarcode from '../../components/code-info-item/ProductBarcode'
import ProductPrice from '../../components/code-info-item/ProductPrice'
import ProductQualityGuaranteePeriod from '../../components/code-info-item/ProductQualityGuaranteePeriod'
import ThreeImg from '../../components/code-info-item/ThreeImg'

export default {
    mounted(){
        this.getCodeInfo()
    },
    name: 'CodeInfo',
    data() {
        return {
            defaultIcon,
            userInfo: {},
            idisCode: '',
            tenant: { id: 0, name: '' },
            template: {
                items: [],
                config: {
                    title: '',
                    carouselList: []
                }
            },
            code: {},
            locationMethod: null,
            token: null
        }
    },
    props: {
        codeId:{
            default: ''
        }
    },
    watch:{
        codeId(){
            this.getCodeInfo()
        }
    },
    computed: {
        musicComp() {
            const list = this.template.items.filter(i => i.type === 'backMusic')
            return list.length == 0 ? null : list[0]
        }
    },
    components: {
        RelatedProduct,
        ProductDesc,
        ProductInfo,
        TemplatePictures,
        TemplateVideo,
        TemplateStylebox,
        TemplateHead,
        TemplateText,
        TemplateCarousel,
        CodeInformation,
        ProductImages,
        ProductPrice,
        ProductQualityGuaranteePeriod,
        ProductBarcode,
        ThreeImg
    },
    methods: {
        async getCodeInfo() {
            const idisCode = this.codeId
            const info = await getJsonResult(`fcapi/wx/codeInformation?codeId=${encodeURIComponent(this.codeId)}`)
            if (!info) {
                this.$message.error('查询码信息失败！')
                return
            }
            const code = info.code
            const template = info.template
            const tenant = info.tenant
            const codeAttr = info.codeAttr
            const productId = code.productId || code.product.id

            // 获取相关信息
            let product, relatedProduct
            for (let item of template.items) {
                if (item.type === 'productIntroduction' || item.type === 'productImages') {
                    // 获取产品信息
                    if (product === undefined) {
                        product = await getJsonResult(`fcapi/wx/item/productInfo?productId=${productId}&codeId=0`)
                    }
                } else if (item.type === 'relatedProduct') {
                    // 获取关联产品
                    if (relatedProduct === undefined) {
                        relatedProduct = await getJsonResult(`fcapi/wx/item/relatedProduct?templateId=${template.id}`)
                        relatedProduct = relatedProduct.map(i => {
                            i.productImage = i.productImage || defaultIcon
                            return i
                        })
                    }
                }
            }

            // 筛选模板中需要隐藏的内容
            template.items = template.items
                .filter(i => i.sort > 0 && i.visible)
                .filter(item => {
                    if (item.type === 'productPrice') {
                        return !!code.price
                    } else if (item.type === 'productQualityGuaranteePeriod') {
                        return info.batch.productionDate && info.batch.beyondShelfLifeDate
                    } else if (item.type === 'antiCounterfeit') {
                        return info.hasCheckCode
                    } else if (item.type === 'productBarcode') {
                        return !!code.barCode
                    } else if (item.type === 'productIntroduction') {
                        return !!product.remark
                    } else if (item.type === 'relatedProduct') {
                        return relatedProduct.length > 0
                    } else if (item.type === 'productImage') {
                        return product.images && product.images.length
                    }
                    return true
                })
                .sort((a, b) => a.sort - b.sort)

            // 设置默认值
            for (const item of template.items) {
                item.value = item.value ? (typeof item.value === 'string' ? JSON.parse(item.value) : {}) : {}
                if (CONFIG[item.type]) {
                    const defaultValue = CONFIG[item.type].defaultValue
                    if (!(defaultValue instanceof Array)) {
                        for (let key of Object.keys(defaultValue)) {
                            if (item.value[key] === undefined) {
                                item.value[key] = defaultValue[key]
                            }
                        }
                    }
                }
            }

            // 设置模板的内容
            for (const item of template.items) {
                let ad = {}
                item.ad = ad
                if (item.type === 'templateHead') {
                    if (!item.value.title) {
                        item.value.title = code.productName
                    }
                } else if (item.type === 'productIntroduction' || item.type === 'productImages') {
                    ad.product = product
                } else if (item.type === 'relatedProduct') {
                    ad.list = relatedProduct
                } else if (
                    item.type === 'productInfo' ||
                    item.type === 'codeInformation' ||
                    item.type === 'productBarcode' ||
                    item.type === 'productPrice' ||
                    item.type === 'productQualityGuaranteePeriod'
                ) {
                    ad.code = code
                    ad.batch = info.batch
                    ad.codeAttrs = codeAttr
                } else if (item.type === 'pictures') {
                    if (item.value.link != null) {
                        item.value.link = item.value.link.replace(/\{productName\}/g, encodeURIComponent(code.productName))
                        item.value.link = item.value.link.replace(/\{batchNo\}/g, encodeURIComponent(code.batchNo))
                        item.value.link = item.value.link.replace(/\{code\}/g, encodeURIComponent(code.idisCode))
                    }
                } else if (item.type === 'carousel') {
                    if (item.value.url != null) {
                        item.value.url = item.value.url.replace(/\{productName\}/g, encodeURIComponent(code.productName))
                        item.value.url = item.value.url.replace(/\{batchNo\}/g, encodeURIComponent(code.batchNo))
                        item.value.url = item.value.url.replace(/\{code\}/g, encodeURIComponent(code.idisCode))
                    }
                } else if (item.type === 'stylebox') {
                    for (const row of item.value.rows) {
                        for (const cell of row) {
                            if (cell.linkType === 'suretyService') {
                                cell.href = `fcapi/wx/surety-service?productId=${productId}&tenantId=${tenant.id}`
                            } else if (cell.linkType === 'warranty') {
                                cell.href = `fcapi/wx/warranty?idisCode=${encodeURIComponent(idisCode)}`
                            } else if (cell.linkType === 'repair') {
                                cell.href = `fcapi/frontend/weixin/repair?idiscode=${encodeURIComponent(idisCode)}`
                            } else if (cell.linkType === 'companyIntroduction') {
                                cell.href = `fcapi/wx/tenant?tenantId=${tenant.id}`
                            } else if (cell.linkType === 'instruction') {
                                cell.href = `fcapi/wx/instruction?productId=${productId}`
                            } else if (cell.linkType === 'contactUs') {
                                cell.href = `fcapi/frontend/weixin/contact-us?tenantId=${tenant.id}`
                            } else if (cell.linkType === 'industrialMall') {
                                cell.href = `fcapi/wx/mall/mallHome?tenantId=${tenant.id}`
                            } else if (cell.linkType === 'officialQuery') {
                                cell.href = `https://dms.citln.cn/whois/ui/searchResult?identity=${encodeURIComponent(
                                    idisCode
                                )}`
                            } else if (cell.linkType === 'maintenanceHistory') {
                                cell.href =
                                    'fcapi/frontend/weixin/maintenance/code-history?idisCode=' + encodeURIComponent(idisCode)
                            } else if (cell.linkType === 'weixinWeapp') {
                                cell.weixinWeappPath = cell.weixinWeappPath.replace(
                                    /\{productName\}/g,
                                    encodeURIComponent(code.productName)
                                )
                                cell.weixinWeappPath = cell.weixinWeappPath.replace(
                                    /\{batchNo\}/g,
                                    encodeURIComponent(code.batchNo)
                                )
                                cell.weixinWeappPath = cell.weixinWeappPath.replace(
                                    /\{code\}/g,
                                    encodeURIComponent(idisCode)
                                )
                            } else if (cell.href != null) {
                                cell.href = cell.href.replace(/\{productName\}/g, encodeURIComponent(code.productName))
                                cell.href = cell.href.replace(/\{batchNo\}/g, encodeURIComponent(code.batchNo))
                                cell.href = cell.href.replace(/\{code\}/g, encodeURIComponent(code.idisCode))
                            }
                        }
                    }
                }
            }
            template.config = JSON.parse(template.config || '{}')
            this.idisCode = idisCode
            this.template = template
            this.code = code
            this.tenant = tenant
        }
    },
    
}
</script>

<style lang="scss" scoped>
.home-msg {
    flex-shrink: 0;
    background-size: cover;
    &-cont {
        background-color: #f4f5f5;
    }
}

.user-avatar {
    position: relative;
    top: -10px;
    z-index: 1000;
    width: 100%;
    height: 60px;

    &-img {
        position: relative;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-color: white;
        z-index: 1002;
    }
    &-bg {
        position: absolute;
        top: 10px;
        z-index: 1001;
        width: 100%;
        height: 50px;
        background-color: white;
    }
}

.mh-image {
    width: 25px;
    height: 25px;
    border-radius: 25px;
    border: 1px solid #e9e9e9;
    margin: 0 10px 0 18px;
}
.mh-text {
    font-size: 16px;
    color: #fff;
    letter-spacing: 2px;
}
.mh-icon {
    font-size: 20px;
    color: #fff;
    margin-right: 20px;
}
.car-image {
    width: 100%;
    height: 100%;
}
.mc-wrap {
    background-color: #ffffff;
    padding: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
    &-title {
        color: #1c2023;
        font-size: 16px;
        text-align: left;
    }
}
.mc-link-circle {
    background-color: #e0e0e0;
    width: 20px;
    height: 20px;
    border-radius: 20px;
}
</style>
