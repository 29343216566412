import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import HomeView from '../views/HomeView.vue'
import InterpretView from "../views/interpret.vue";
import TraceManage from "../views/traceManage.vue";
import SearchResult from "../views/SearchResult.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/interpret',
    name: 'interpret',
    component: InterpretView
  },
  {
    path: '/traceManage',
    name: 'traceManage',
    component: TraceManage
  },
  {
    path: '/searchResult',
    name: 'searchResult',
    component: SearchResult
  },
]

const router = new VueRouter({mode: 'history',routes})

export default router
