<template>
  <div>
    <HeaderWeb v-if="isWeb"></HeaderWeb>
    <HeaderMob v-if="!isWeb"></HeaderMob>
    <router-view></router-view>
    <FooterWeb v-if="isWeb"></FooterWeb>
    <FooterMob v-if="!isWeb"></FooterMob>
  </div>
</template>

<script>
import { getPcOrMob } from './utils/utils'
import HeaderMob from './components/header/header-mob.vue'
import HeaderWeb from './components/header/header-web.vue'
import FooterWeb from './components/footer/footer-web.vue'
import FooterMob from './components/footer/footer-mob.vue'

export default{
  mounted() {
    setInterval(() => {
        this.isWeb = getPcOrMob()
    }, 100)
  },
  data() {
    return {
      isWeb: true,
    }
  },
  components: {
    HeaderMob, HeaderWeb, FooterWeb, FooterMob
  },
};
</script>

<style lang="scss">
*{
  margin: 0;
  padding: 0;
}

</style>
