<template>
    <!--产品信息-->
    <div class="mc-info mc-wrap">
        <!--头部-->
        <div class="flex-sb mb10">
            <special-title title="产品信息" enTitle="PRODUCT INFORMATION"></special-title>
            <a
                :href="`/wx/trace-source?codeId=${code.id}`"
                target="_blank"
                class="mc-info-btn"
                v-show="item.value.showTrace"
            >
                溯源查询
            </a>
        </div>
        <table class="mc-info-cont">
            <tr :class="[{ dash: i === 0 }]" v-for="(io, i) in infoList" :key="i">
                <td :class="['mc-info-cell', 'mc-info-cell-left']">
                    <div class="c-1C2023" style="word-break:break-all;">{{ io.name }}</div>
                </td>
                <td :class="['mc-info-cell', 'mc-info-cell-right']">
                    <p class="c-1C2023" style="word-break:break-all;color: #4970e0;" v-if="io.isUrl">
                        <a :href="io.value">{{ io.value }}</a>
                    </p>
                    <p class="c-1C2023" style="word-break:break-all;color: #4970e0;" v-else>{{ io.value }}</p>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
import SpecialTitle from './SpecialTitle'

export default {
    props: {
        item: Object,
        code: Object,
        codeAttrs: Array,
        batch: Object
    },
    computed: {
        infoList() {
            const res = [
                { name: '产品名称', value: this.code.productName, isUrl: false },
                { name: '产品编号', value: this.code.productNo, isUrl: false },
                { name: '产品条码', value: this.code.barCode, isUrl: false },
                { name: '产品分类', value: this.code.categoryName, isUrl: false },
                { name: '产品单位', value: this.code.unit, isUrl: false }
            ].filter(i => i.value && i.value.trim())
            if (this.codeAttrs) {
                for (let i of this.codeAttrs) {
                    if ((!i.references || i.references.length == 0) && !(i.attrCategoryId || i.attrCategoryName)) {
                        if (i.attrValue.startsWith('http') || i.attrValue.startsWith('HTTP')) {
                            res.push({
                                name: i.attrNameCn,
                                value: i.attrValue,
                                isUrl: true
                            })
                        } else {
                            res.push({
                                name: i.attrNameCn,
                                value: i.attrValue,
                                isUrl: false
                            })
                        }
                    }
                }
            }
            return res
        }
    },
    components: {
        SpecialTitle
    }
}
</script>

<style lang="scss" scoped>
//产品信息
.mc-info {
    padding: 15px;
    background-color: #fff;
    &-title {
        color: #1c2023;
        font-size: 16px;
    }
    &-btn {
        background: url('~../../assets/img/sy.png') no-repeat center;
        background-size: cover;
        width: 85px;
        height: 24px;
        padding-left: 25px;
        color: #fff;
        font-size: 12px;
        line-height: 24px;
        cursor: pointer;
    }
    &-circle {
        margin-right: 14px;
        width: 5px;
        height: 5px;
        background-color: #2b6dda;
        border-radius: 10px;
    }
    &-rect {
        border-radius: 0;
    }
}

.mc-info-cont {
    width: 100%;
    font-size: 14px;
    > tr {
        border-bottom: 1px solid #fff;
        color: #b6b6b6;
        > p:last-child {
            color: #1c2023;
        }
    }
}
.mc-info-cell {
    height: 100%;
    width: 30%;
    padding: 3px;
    background: #f7f7f7;
    div {
        min-height: 30px;
        display: flex;
        align-items: center;
    }
}
.mc-info-cell-left {
    width: 30%;
    div {
        justify-content: center;
    }
}
.mc-info-cell-right {
    width: 70%;
    text-align: right !important;
    padding-right: 8px;
    div {
        margin-left: 10px;
    }
}
</style>
