<template>
    <div class="home-msg-head flex-v-center hoverStyle" style="backgroundColor: #fff">
        <el-image
            v-if="item.value.icon != ''"
            class="mh-image"
            :src="imageSrc(item.value.icon)"
            :lazy="true"
        ></el-image>
        <p :class="[item.value.icon != '' ? 'mh-text' : 'mh-text1']">
            {{ item.value.title }}
        </p>
    </div>
</template>

<script>
export default {
    props: {
        item: Object
    },

    methods: {
        imageSrc(src) {
            if (src && src.startsWith('http')) {
                return src + '?x-image-process=style/width-1024'
            }
            return src
        }
    }
}
</script>

<style scoped>
.home-msg-head {
    height: 50px;
    justify-content: flex-start;
}
.mh-image {
    width: 25px;
    height: 25px;
    border-radius: 25px;
    border: 1px solid #e9e9e9;
    margin: 0 10px 0 18px;
}
.mh-text {
    font-size: 16px;
    color: #000;
    letter-spacing: 2px;
}
.mh-text1 {
    margin: 0 auto;
    font-size: 16px;
    color: #000;
    letter-spacing: 2px;
}
</style>
