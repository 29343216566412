var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stylebox-item",style:({
        backgroundColor: _vm.config.backcolor,
        backgroundImage: _vm.config.backimage ? `url(${_vm.imageSrc(_vm.config.backimage)})` : ''
    })},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.config.fonticon),expression:"config.fonticon"}],class:[_vm.config.fonticon, 'image'],style:({
            color: _vm.config.forecolor
        })}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.config.imgIcon),expression:"config.imgIcon"}],staticClass:"imgIcon",attrs:{"src":_vm.config.imgIcon}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.config.title),expression:"config.title"}],staticClass:"title",style:({
            textAlign: _vm.config.titleAlign,
            color: _vm.config.forecolor
        })},[_vm._v(" "+_vm._s(_vm.config.title)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }