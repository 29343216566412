<template>
    <div class="mc-info" style="background:#fff">
        <div style="padding:15px">
            <special-title title="产品图片"></special-title>
        </div>

        <el-carousel height="180px" arrow="never" style="background-color: #ffffff">
            <template v-if="imageLength">
                <el-carousel-item v-for="(src, idx) in images" :key="idx">
                    <img class="car-image" :src="imageSrc(src)" />
                </el-carousel-item>
            </template>
            <template v-else>
                <p style="color: #888888; position: relative; top: 70px;">(请在产品设置中上传图片)</p>
            </template>
        </el-carousel>
    </div>
</template>

<script>
import SpecialTitle from './SpecialTitle'
export default {
    components: {
        SpecialTitle
    },
    props: {
        item: Object,
        images: Array
    },
    computed: {
        imageLength() {
            return this.images.length
        }
    },
    methods: {
        imageSrc(src) {
            if (src.startsWith('http')) {
                return src + '?x-image-process=style/width-1024'
            }
            return src
        }
    }
}
</script>

<style scoped>
.car-image {
    max-width: 100%;
    max-height: 180px;
}
</style>
