import { render, staticRenderFns } from "./TemplateText.vue?vue&type=template&id=6b360671&scoped=true"
import script from "./TemplateText.vue?vue&type=script&lang=js"
export * from "./TemplateText.vue?vue&type=script&lang=js"
import style0 from "./TemplateText.vue?vue&type=style&index=0&id=6b360671&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b360671",
  null
  
)

export default component.exports