<template>
    <div class="mc-wrap f14">
        <div :style="item.value.style" v-if="item.value.text">{{ item.value.text }}</div>
        <p class="weixin-empty-hint" v-else>(无文本)</p>
    </div>
</template>

<script>
import '../../assets/css/weixin.scss'

export default {
    props: {
        item: Object
    }
}
</script>

<style type="scss" scoped>
.mc-wrap {
    background-color: #fff;
    padding: 20px 14px 20px 14px !important;
    word-break: break-all;
    text-align: justify;
}
</style>
