// 组件的配置信息

import { format } from '../../utils/date-utils'
const now = new Date()

export default {
    templateHead: {
        title: '标题栏',
        type: 'templateHead',
        defaultValue: {
            title: '',
            icon: '',
            bgcolor: '#004da1'
        },
        must: false,
        limit: 1,
        validate(item) {
            if (!item.value.title) {
                return '请输入标题'
            }
        }
    },
    backMusic: {
        title: '背景音乐',
        type: 'backMusic',
        defaultValue: {
            music: '',
            bgcolor: '#004da1'
        },
        must: false,
        limit: 1,
        validate(item) {
            if (!item.value.music) {
                return '请上传背景音乐'
            }
        }
    },
    carousel: {
        title: '轮播图',
        type: 'carousel',
        defaultValue: {
            height: '200px',
            images: []
        },
        must: false,
        limit: 1,
        validate(item) {
            if (!item.value.images.length) {
                return '请选择轮播图图片'
            }
            if (
                item.value.url != null &&
                item.value.url != '' &&
                !item.value.url.match(/^https?:\/\/([^.]+\.)+[^.]+$/)
            ) {
                return '自定义的链接需要填写带http:// 或者https:// 的有效连接'
            }
        }
    },
    productIntroduction: {
        title: '产品介绍',
        type: 'productIntroduction',
        sampleData: {},
        defaultValue: {},
        must: false,
        limit: 1
    },
    relatedProduct: {
        title: '特别推荐',
        type: 'relatedProduct',
        sampleData: {},
        must: false,
        limit: 1,
        defaultValue: {}
    },
    antiCounterfeit: {
        title: '验证查询',
        type: 'antiCounterfeit',
        defaultValue: {
            configArea: 'query',
            buttonColor: '#004DA1',
            buttonText: '码上验证',
            buttonTextColor: '#ffffff',
            successTemplate: 1,
            failureTemplate: 2,
            invalidTitle: '您所查询的产品已被多次查询!',
            queryHistory: true,
            validTitle: '您所查询的产品是正品!',
            validVoice: true,
            validateText: ''
        },
        sampleData: {},
        must: false,
        limit: 1
    },
    text: {
        title: '文本',
        type: 'text',
        defaultValue: {
            text: '',
            style: ''
        },
        must: false,
        limit: 4,
        validate(item) {
            if (!item.value.text) {
                return '请输入文本'
            }
        }
    },
    video: {
        title: '视频',
        type: 'video',
        defaultValue: {
            src: ''
        },
        must: false,
        limit: 4,
        validate(item) {
            if (!item.value.src) {
                return '请上传视频'
            }
        }
    },
    pictures: {
        title: '图片',
        type: 'pictures',
        defaultValue: {
            images: [],
            link: ''
        },
        must: false,
        limit: 10,
        validate(item) {
            if (!item.value.images) {
                return '请至少上传1 张图片'
            }
            const link = item.value.link
            if (link && !link.match(/^https?:\/\/([^.]+\.)+[^.]+$/)) {
                return '自定义的链接需要填写带http:// 或者https:// 的有效连接'
            }
        }
    },
    productInfo: {
        title: '产品信息',
        type: 'productInfo',
        sampleData: {},
        must: false,
        limit: 1,
        defaultValue: {}
    },
    stylebox: {
        title: '宫格',
        type: 'stylebox',
        must: false,
        limit: 4,
        defaultValue: {
            rows: [
                [
                    {
                        size: 1,
                        title: '标题',
                        linkType: 'none',
                        href: '',
                        fonticon: 'el-icon-menu',
                        imgIcon: '',
                        forecolor: '#FFFFFF',
                        titleAlign: 'center',
                        backcolor: '#004da1'
                    },
                    {
                        size: 1,
                        title: '标题',
                        linkType: 'none',
                        href: '',
                        fonticon: 'el-icon-menu',
                        imgIcon: '',
                        forecolor: '#FFFFFF',
                        titleAlign: 'center',
                        backcolor: '#004da1'
                    },
                    {
                        size: 1,
                        title: '标题',
                        linkType: 'none',
                        href: '',
                        fonticon: 'el-icon-menu',
                        imgIcon: '',
                        forecolor: '#FFFFFF',
                        titleAlign: 'center',
                        backcolor: '#004da1'
                    }
                ]
            ]
        },
        validate(item) {
            const row = item.value.rows[0]
            for (const cell of row) {
                if (cell.linkType === 'normal') {
                    if (!cell.href || !cell.href.match(/^https?:\/\/([^.]+\.)+[^.]+$/)) {
                        return '自定义的链接需要填写带http:// 或者https:// 的有效连接'
                    }
                } else if (cell.linkType === 'qrCodeScan') {
                    if (!cell.qrCodeImage) {
                        return '关注微信链接需要上传二维码'
                    }
                } else if (cell.linkType === 'kefuMobile') {
                    if (!cell.kefuMobile) {
                        return '请填写电话号码'
                    }
                }
            }
        }
    },
    productBarcode: {
        title: '产品条码',
        type: 'productBarcode',
        defaultValue: {},
        sampleData: {
            code: {
                barCode: '8916318143811113'
            }
        },
        limit: 1,
        must: false
    },
    productQualityGuaranteePeriod: {
        title: '保质期',
        type: 'productQualityGuaranteePeriod',
        defaultValue: {},
        sampleData: {
            batch: {
                productionDate: '2020-01-01',
                beyondShelfLifeDate: format(now, 'yyyy-MM-dd')
            }
        },
        limit: 1,
        must: false
    },
    productPrice: {
        title: '产品价格',
        type: 'productPrice',
        defaultValue: {},
        sampleData: {},
        limit: 1,
        must: false
    },
    productImages: {
        title: '产品图片',
        type: 'productImages',
        defaultValue: {},
        sampleData: {},
        limit: 1,
        must: false
    },
    codeInformation: {
        title: '标识码',
        type: 'codeInformation',
        defaultValue: {},
        sampleData: {
            code: {
                idisCode: '88.118.8/KIT001'
            }
        },
        limit: 1,
        must: false
    },
    threeImg: {
        title: '3D旋转视图',
        type: 'threeImg',
        limit: 1,
        must: false,
        defaultValue: {
            images: []
        }
    }
}
