<!--关联产品-->
<template>
    <div class="mc-wrap f14">
        <special-title title="特别推荐" enTitle="FEATURED"></special-title>
        <template v-if="list && list.length">
            <div class="flex-sr" v-for="i in parseInt((list.length + 2) / 3)" :key="i">
                <div
                    v-for="(link, l) in list.slice(i * 3 - 3, Math.min(i * 3, list.length))"
                    :key="l"
                    style="width: 30%;"
                >
                    <a target="_blank" :href="`${link.linkUrl}`">
                        <el-image :src="imageSrc(link.productImage)" fit="fill"></el-image>
                    </a>
                </div>
            </div>
        </template>
        <div v-else>
            <p style="color: #888888; margin: 10px; ">
                请在营销管理->特别推荐菜单中设置特别推荐的商品!
            </p>
        </div>
    </div>
</template>

<script>
import SpecialTitle from './SpecialTitle'
export default {
    props: ['list'],
    components: {
        SpecialTitle
    },
    methods: {
        imageSrc(src) {
            if (typeof src === 'string' && src.startsWith('http')) {
                return src + '?x-image-process=style/width-1024'
            }
            return src
        }
    }
}
</script>

<style scoped>
.mc-wrap {
    padding: 15px !important;
}

.flex-sr {
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-bottom: none;
    margin-top: 10px;
    margin-bottom: 10px;
}
</style>
