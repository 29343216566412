<template>
    <div class="search-result-web">
        <div class="breadcrumb-box">
            <div class="breadcrumb-title">当前位置：</div>
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>查询结果</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="search-box">
            <el-input v-model="code" placeholder="请输入要查询的标识码" @change="getCodeInfo">
                <template #append>
                    <div class="icon-box" @click="getCodeInfo">
                        <svg t="1658469896542" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4232" id="mx_n_1658469896546" width="200" height="200"><path d="M680.728354 651.326209c121.274064-137.208988 116.320239-346.908988-14.939247-478.166427-136.444579-136.433322-357.662913-136.433322-494.094188 0-136.443555 136.442532-136.443555 357.661889 0 494.105445 131.241067 131.253346 340.927763 136.204102 478.149031 14.942317l265.63187 265.63187 30.889521-30.877241L680.728354 651.326209zM649.273968 622.002346l-28.658713 28.668946c-120.345925 105.622596-303.678394 101.031021-418.524049-13.812587-119.651101-119.651101-119.651101-313.648466 0-433.299567C321.742307 83.909062 515.740696 83.909062 635.39282 203.569372 750.211868 318.380234 754.826979 501.656421 649.273968 622.002346z" p-id="4233" fill="#ffffff"></path></svg>
                    </div>
                </template>
            </el-input>
        </div>
        <div class="search-result-content">
            <div class="content-title">查询结果</div>
            <div v-show="!code" class="no-code">请输入标识码以查看标识详情</div>
            <div class="result-box" v-if="code&&!showCodeInfoComp">
                <div class="attr-item" v-for="item, index in codeInfo" :key="index">
                    <div class="item-title">{{ item.title }}：</div>
                    <div class="item-text">{{ item.text }}</div>
                </div>
            </div>
            <CodeInfo v-if="code&&showCodeInfoComp" :codeId="code" class="fc-code-info"></CodeInfo>
        </div>
    </div>
</template>

<script>
import CodeInfo from "../code-info/code-info.vue";

export default {
    name: 'SearchResultWeb',
    mounted(){
        this.code = this.$route.query.code
        this.code ? this.getCodeInfo() : ''
    },
    data() {
        return {
            code: '',
            showCodeInfoComp: false,
            searchImg: require("../../assets/icon/search.png"),
            codeInfo: []
        }
    },
    components: {
        CodeInfo
    },
    methods: {
        goHomePage(){
            this.$router.push('/')
        },
        getFCCodeInfo(){
            this.showCodeInfoComp = true
        },
        getOtherCodeInfo(){
            this.showCodeInfoComp = false
            this.codeInfo = []
            this.$axios.get(`erfcapi/pms/whois/handleIsExist?handle=${encodeURIComponent(this.code)}`).then(res=>{
                if(res.data&&res.data.status == -2){
                    res.data.data.forEach(element => {
                        this.codeInfo.push({
                            title: element.name,
                            text: element.realVal
                        })
                    });
                }else{
                    this.$message.error("查询码信息失败！")
                }
            })
        },
        getCodeInfo(){
            this.code.includes("88.118") ? this.getFCCodeInfo() : this.getOtherCodeInfo()
        }
    },
};
</script>
<style lang="scss" scoped>
    .search-result-web{
        width: 1080px;
        margin: 0 auto;
        .breadcrumb-box{
            margin-top: 28px;
            display: flex;
            height: 17px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #151515;
            line-height: 17px;
            margin-bottom: 60px;
            ::v-deep .el-breadcrumb{
                height: 17px;
                line-height: 17px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #151515;
                .el-breadcrumb__item{
                    height: 17px;
                    line-height: 17px;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #151515;
                }
            }
        }
        .search-box{
            width: 780px;
            margin: 0 auto;
            margin-bottom: 60px;
            ::v-deep .el-input__inner{
                height: 54px;
                border-top-left-radius: 27px;
                border-bottom-left-radius: 27px;
                padding: 1px 20px;
                height: 52px;
            }
            ::v-deep .el-input-group__append{
                position: relative;
                right: 1px;
                border-left: none; 
                background-color: #FFFFFF;
                border-top-right-radius: 27px;
                border-bottom-right-radius: 27px;
            }
            .mark-box{
                width: 2px;
                height: calc(100% - 2px);
                position: relative;
                right: 21px;
                background-color: #ffffff;
            }
            .icon-box{
                width: 100px;
                height: 44px;
                position: relative;
                left: 16px;
                background: #B42620;
                border-radius: 35px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                svg{
                    height: 35px;
                    width: 35px;
                }
            }
        }
        .search-result-content{
            width: 1080px;
            margin: 0 auto;
            .content-title{
                height: 56px;
                font-size: 40px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #151515;
                line-height: 56px;
                letter-spacing: 1px;
                text-align: center;
                margin-bottom: 40px;
            }
            .no-code{
                padding: 20px;
                text-align: center;
                margin-bottom: 40px;
            }
            .result-box{
                width: 780px;
                margin: 0 auto;
                margin-bottom: 100px;
                .attr-item{
                    margin-bottom: 16px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #151515;
                    display: flex;
                    justify-content: space-between;
                    line-height: 20px;
                    .item-title{
                        height: 100%;
                        display: inline-block;
                        width: 200px;
                        margin-right: 10px;
                    }
                    .item-text{
                        display: inline-block;
                        width: 520px;
                        text-align: right;
                        word-break: break-all;
                        margin-left: 10px;
                    }
                }
            }
            .fc-code-info{
                width: 400px;
                margin: 0 auto;
                margin-bottom: 40px;
            }
        }
    }
</style>
