<template>
    <div class="card-item">
        <img :src="cardData.bgImg">
        <div class="text-box">
            <div class="number-box">
                <span class="number">{{ cardData.number }}</span>
                <span class="unit">{{ cardData.unit }}</span>
            </div>
            <div class="text">{{ cardData.text }}</div>
        </div>
    </div>
    
</template>

<script>
export default {
    name: 'CardItem',
    data() {
        return {
            
        }
    },
    components: {
        
    },
    props:{
        cardData:{
            default:{
                number: 187,
                unit: '家',
                text: '已注册187家企业',
                bgImg: require("../../assets/img/code.png")
            },
        }
    }
};
</script>

<style scoped lang="scss">
    .card-item{
        width: 320px;
        height: 172px;
        position: relative;
        .text-box{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            max-width: 100%;
            .number-box{
                margin-bottom: 16px;
                text-align: center;
                .number{
                    display: inline-block;
                    height: 56px;
                    font-size: 40px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #FFFFFF;
                    line-height: 56px;
                    letter-spacing: 1px;
                }
                .unit{
                    display: inline-block;
                    height: 20px;
                    font-size: 14px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #FFFFFF;
                    line-height: 20px;
                }
            }
            .text{
                text-align: center;
                height: 20px;
                font-size: 14px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #FFFFFF;
                line-height: 20px;
                min-width: 200px;
            }
        }
    }
</style>
