<template>
    <div class="search-result-mob">
        <div class="search-box">
            <div class="title-input">
                <div>
                    <el-input v-model="code" placeholder="请输入要查询的标识码" @change="getCodeInfo"></el-input>
                    <img :src="scanIcon" class="scan-icon" @click="showScan = true" />
                </div>
                <div class="search-btn" @click="getCodeInfo">查询验证</div>
            </div>
        </div>
        <div class="search-result-content">
            <el-divider><div class="content-title">查询结果</div></el-divider>
            <div v-show="!code" class="no-code">请输入标识码以查看标识详情</div>
            <div class="result-box" v-if="code&&!showCodeInfoComp">
                <div class="attr-item" v-for="(item, index) in codeInfo" :key="index">
                    <div class="item-title">{{ item.title }}：</div>
                    <div class="item-text">{{ item.text }}</div>
                </div>
            </div>
            <CodeInfo v-if="code&&showCodeInfoComp" :codeId="code" class="fc-code-info"></CodeInfo>
        </div>
        <el-dialog title="请扫码" :visible.sync="showScan" :fullscreen="true">
            <QrCode :show="showScan" @getScanResult="getScanResult"></QrCode>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showScan = false">取 消</el-button>
                <el-button type="primary" @click="showScan = false">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import QrCode from "../QrCode.vue";
import CodeInfo from "../code-info/code-info.vue";

export default {
    mounted(){
        this.code = this.$route.query.code
        this.code ? this.getCodeInfo() : ''
    },
    name: 'SearchResultMob',
    data() {
        return {
            code:'',
            showCodeInfoComp: false,
            scanIcon: require("../../assets/icon/saoyisao.png"),
            codeInfo: [],
            showScan: false
        }
    },
    components: {
        QrCode, CodeInfo
    },
    methods: {
        getFCCodeInfo(){
            this.showCodeInfoComp = true
        },
        getOtherCodeInfo(){
            this.showCodeInfoComp = false
            this.codeInfo = []
            this.$axios.get(`erfcapi/pms/whois/handleIsExist?handle=${encodeURIComponent(this.code)}`).then(res=>{
                if(res.data&&res.data.status == -2){
                    res.data.data.forEach(element => {
                        this.codeInfo.push({
                            title: element.name,
                            text: element.realVal
                        })
                    });
                }else{
                    this.$message.error("查询码信息失败！")
                }
            })
        },
        getCodeInfo(){
            this.code.includes("88.118") ? this.getFCCodeInfo() : this.getOtherCodeInfo()
        },
        getScanResult(code){
            this.showScan = false
            this.code = code
            this.getCodeInfo()
        }
    },
};
</script>
<style lang="scss" scoped>
    ::v-deep .el-dialog__footer{
        margin-top: 300px;
    }
    .search-result-mob{
        width: 100%;
        .search-box{
            width: 100%;
            position: relative;
            height: 158px;
            .title-input{
                position: absolute;
                top: 50%;
                left: 50%;
                width: 75%;
                transform: translate(-50%,-50%);
                .el-input{
                    width: 100%;
                }
                ::v-deep .el-input__inner{
                    border-radius: 20px;
                    border-color: #B42620;
                    padding: 1px 10px;
                    height: 40px;
                    width: 100%;
                }
                .scan-icon{
                    width: 18px;
                    height: 18px;
                    position: absolute;
                    right: 20px;
                    bottom: 67px;
                    cursor: pointer;
                }
                .search-btn{
                    color: white;
                    margin: 0 auto;
                    width: 100px;
                    height: 32px;
                    line-height: 32px;
                    background-color: #B42620;
                    text-align: center;
                    cursor: pointer;
                    margin-top: 24px;
                }
            }
        }
        .search-result-content{
            width: 100%;
            .content-title{
                height: 24px;
                font-size: 17px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #191919;
                line-height: 24px;
            }
            .no-code{
                padding: 20px;
                text-align: center;
            }
            .result-box{
                box-sizing: border-box;
                width: 100%;
                margin: 0 auto;
                padding: 22px 15px 32px;
                .attr-item{
                    margin-bottom: 16px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #151515;
                    display: flex;
                    justify-content: space-between;
                    line-height: 20px;
                    .item-title{
                        height: 100%;
                        display: inline-block;
                        width: 200px;
                        margin-right: 10px;
                    }
                    .item-text{
                        display: inline-block;
                        width: 520px;
                        text-align: right;
                        word-break: break-all;
                        margin-left: 10px;
                    }
                }
            }
            .fc-code-info{
                margin-bottom: 40px;
            }
        }
    }  
</style>
