<template>
    <div class="footer-class">
        <div class="links-class">
            <div class="links-title">友情链接：</div>
            <div class="links-item"><a href="https://www.miit.gov.cn/" target="_blank">中华人民共和国工业和信息化部</a></div>
            <div class="links-item"><a href="http://www.caict.ac.cn/" target="_blank">中国信息通信研究院</a></div>
            <div class="links-item"><a href="https://www.citln.cn/" target="_blank">中国工业互联网顶级节点</a></div>
        </div>
        <div class="contact-us">
            <div class="contact-left">
                <div class="form-div">
                    <div class="form-title">留下您的预约信息，我们将第一时间联系您！</div>
                    <el-input v-model="addInfo.companyName" placeholder="请输入您公司名称" class="compony-name-input" />
                    <el-input v-model="addInfo.name" placeholder="请输入您的姓名" class="other-input mag-right" />
                    <el-input v-model="addInfo.position" placeholder="请输入您的职位" class="other-input" />
                    <el-input v-model="addInfo.phoneNumber" placeholder="请输入您的手机号码" class="other-input mag-right" />
                    <el-input v-model="addInfo.email" placeholder="请输入您的邮箱" class="other-input" />
                    <div class="submitBtn" @click="sunmitInfo">预约试用</div>
                    <div style="clear: both;"></div>
                </div>
                <div class="copy-right">Copyright 2022-2027  fc3de.com 南京复创智能制造技术有限公司版权所有 <a href="http://beian.miit.gov.cn/">苏ICP备2022027126号-2</a></div>
            </div>
            <div class="contact-right">
                <div class="right-content">
                    <div class="phone-title">咨询热线：</div>
                    <div class="phone-number">400-106-2918</div>
                    <div class="weixin">官方微信</div>
                    <div class="weixin-qrcode"><img :src="weixinQrcode"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FooterWeb',
    data() {
        return {
            weixinQrcode: require("../../assets/img/weixin-qrcode.png"),
            addInfo: {
                companyName: '',
                name:'',
                position: '',
                phoneNumber: '',
                email: ''
            }
        }
    },
    components: {
        
    },
    methods:{
        sunmitInfo(){
            if(!this.addInfo.companyName || !this.addInfo.name || !this.addInfo.position || !this.addInfo.phoneNumber || !this.addInfo.email){
                this.$message.error('需要填写的信息不全，请检查！')
                return
            }
            var phoneNumberReg = /^1[3-9]\d{9}$/
            if(!phoneNumberReg.test(this.addInfo.phoneNumber)){
                this.$message.error('手机号码格式不正确！')
                return
            }
            var emailReg = /^([a-zA-Z\d][\w-]{2,})@(\w{2,})\.([a-z]{2,})(\.[a-z]{2,})?$/
            if(!emailReg.test(this.addInfo.email)){
                this.$message.error('邮箱格式不正确！')
                return
            }
            this.$axios.post('fcapi/contactUs/addItem',{
                tenantId: 1,
                companyName: this.addInfo.companyName,
                name: this.addInfo.name,
                contact: this.addInfo.phoneNumber,
                position: this.addInfo.position,
                mail: this.addInfo.email
            }).then(res => {
                if(res&&res.data.code == 200){
                    this.$message.success("预约成功！请耐心等候消息。")
                }else{
                    this.$message.error("预约失败！")
                }
            })
        }
    }
};
</script>

<style scoped lang="scss">
    .footer-class{
        min-width: 1080px;
        width: 100%;
        .links-class{
            width: 100%;
            height: 50px;
            background-color: #F2F2F2;
            display: flex;
            align-items: center;
            justify-content: center;
            .links-title{
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #151515;
                margin-right: 16px;
            }
            .links-item{
                font-size: 14px;
                color: #B42620;
                margin-right: 48px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #B42620;
                a{
                    color: #B42620;
                    text-decoration: underline;
                }
            }
        }
        .contact-us{
            width: 1080px;
            padding: 40px 0;
            margin: 0 auto;
            height: 240px;
            display: flex;
            justify-content: space-between;
            .contact-left{
                display: block;
                .form-div{
                    width: 500px;
                    .form-title{
                        height: 20px;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #B42620;
                        line-height: 20px;
                        margin-bottom: 10px;
                    }
                    ::v-deep .el-input__inner{
                        height: 32px !important;
                        line-height: 32px !important;
                    }
                    .compony-name-input{
                        width: 500px;
                        margin-bottom: 10px;
                    }
                    .other-input{
                        width: 240px;
                        margin-bottom: 10px;
                    }
                    .mag-right{
                        margin-right: 20px;
                    }
                    .submitBtn{
                        float: right;
                        text-align: center;
                        cursor: pointer;
                        width: 100px;
                        height: 32px;
                        line-height: 32px;
                        background: #B42620;
                        border-radius: 2px;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #FFFFFF;
                        letter-spacing: 1px;
                    }
                }
                .copy-right{
                    height: 20px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #151515;
                    line-height: 20px;
                    margin-top: 32px;
                    margin-bottom: 40px;
                    a {
                        color: #151515;
                        text-decoration: none;
                    }
                    a:hover {
                        color: #ba2636;
                        text-decoration: underline;
                    }
                }
            }
            .contact-right{
                margin-right: 70px;
                border-left: 1px solid #CFCFCF;
                height: 235.5px;
                .right-content{
                    margin-left: 153px;
                    .phone-title{
                        margin-bottom: 8px;
                        height: 20px;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #151515;
                        line-height: 20px;
                    }
                    .phone-number{
                        height: 20px;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #151515;
                        line-height: 20px;
                        margin-bottom: 48px;
                    }
                    .weixin{
                        margin-bottom: 8px;
                        height: 20px;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #151515;
                        line-height: 20px;
                    }
                    .weixin-qrcode{
                        width: 108px;
                        height: 108px;
                        img{
                            width: 108px;
                            height: 108px;
                        }
                    }
                }
            }
        }
    }
</style>
