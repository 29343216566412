<template>
    <div class="trace-manage">
        <!-- banner图 -->
        <div class="trace-manage_banner">
            <div class="banner-title">
                国家标识解析追溯防伪平台
            </div>
            <img :src="bannerImg" />
        </div>
        <!-- tip -->
        <div class="trace-manage_tip">
            <a name="traceIn" />
            <!-- pc -->
            <div class="tip-pc">
                <div class="trace-manage_tipTitle">
                    <!-- 左侧 -->
                    <div class="tipTitle-left">
                        <div class="tipTitle-left-top">
                            <div>
                                <p>工业互联网是第四次工业革命的基石，工业互联网</p>
                                <p>标识解析体系是支撑工业互联网体系的神经枢纽。</p>
                            </div>
                        </div>
                        <div class="tipTitle-left-bottom">
                            <div>
                                <span>
                                    工业互联网标识解析体系是，通过为机器、产品等物理资源和算法、工艺等虚拟资源赋予唯一“身份证”并进行快速定位和信息查询，实现跨企业、跨行业、跨地域的信息资源集成共享，是全球供应链系统和企业生产系统精准对接、产品全生命周期管理和智能化服务的前提基础。
                                </span>
                            </div>
                        </div>
                    </div>
                    <!-- 右侧 -->
                    <div class="tipTitle-right">
                        <img :src="bitMapImg" />
                    </div>
                </div>
            </div>
            <!-- mobile -->
            <div class="tip-mob">
                <div class="tip-mob-title">
                    <div class="mob-title-top">
                        <div>
                            <span>工业互联网是第四次工业革命的基石，工业互联网标识解析体系是支撑工业互联网体系的神经枢纽。</span>
                        </div>
                    </div>
                    <div class="mob-title-bottom">
                        <div>
                            <span>
                                工业互联网标识解析体系是，通过为机器、产品等物理资源和算法、工艺等虚拟资源赋予唯一“身份证”并进行快速定位和信息查询，实现跨企业、跨行业、跨地域的信息资源集成共享，是全球供应链系统和企业生产系统精准对接、产品全生命周期管理和智能化服务的前提基础。
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- introduction -->
        <div class="trace-manage_introduction trace-manage_common">
            <a name="traceIntro" />
            <!-- introduction pc -->
            <div class="common-maxWidth introduction-pc">
                <div class="introduction-title common-title">
                    <p>功能介绍</p>
                </div>
                <div class="introduction-cards">
                    <div class="introduction-cards-item common-cards-item">
                        <div>
                            <img :src="introductionYwymImg" />
                            <div class="intro-cards-hover">
                                <p>一物一码</p>
                            </div>
                            <div class="intro-cards-mainHover">
                                <p>一物一码</p>
                                <p>
                                    <span>
                                        一物一码结算通过为每个商品分配唯一编码，实现商品交易结算，可建立基于唯一标识的多应用模式。基于一物一码结算所产生的交易数据。
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div>
                            <img :src="introductionFwsyImg" />
                            <div class="intro-cards-hover">
                                <p>防伪追溯</p>
                            </div>
                            <div class="intro-cards-mainHover">
                                <p>防伪追溯</p>
                                <p>
                                    <span>
                                        在产品流通环节中，企业与消费者可基于标识写入的数据对产品质量进行追溯，同时基于标识的高级数字防伪与传统的标签防伪技术相结合，让用户通过扫码便可判断产品真伪。
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div>
                            <img :src="introductionYxImg" />
                            <div class="intro-cards-hover">
                                <p>数字营销</p>
                            </div>
                            <div class="intro-cards-mainHover">
                                <p>数字营销</p>
                                <p>
                                    <span>
                                        面向生产制造行业，产品进入流通领域后，在统一编码规范基础上，通过标识解析基础设施获取运营数据、消费数据，赋能企业搭建线上营销闭环；并通过标识大数据，辅助企业开展市场策略。
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="introduction-cards-item common-cards-item">
                        <div>
                            <img :src="introductionZhshImg" />
                            <div class="intro-cards-hover">
                                <p>智慧售后</p>
                            </div>
                            <div class="intro-cards-mainHover">
                                <p>智慧售后</p>
                                <p>
                                    <span>
                                        基于标识的防伪功能与传统的标签防伪技术相结合，让用户通过扫码便可判断产品真伪。防窜货应用通过对物流环节进行控制，记录产品的每个流转节点，明确商品流向。
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div>
                            <img :src="introductionGylImg" />
                            <div class="intro-cards-hover">
                                <p>数字化供应链</p>
                            </div>
                            <div class="intro-cards-mainHover">
                                <p>数字化供应链</p>
                                <p>
                                    <span>
                                        配套生产企业、仓储物流企业、整机制造企业在标识解析体平台注册、解析、查询，促进生产、运输、使用、服务等环节的数字化高效协同，有效提升企业资源优化配置，实现优化供应链管理。
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div>
                            <img :src="introductionSmzqImg" />
                            <div class="intro-cards-hover">
                                <p>全生命周期管理</p>
                            </div>
                            <div class="intro-cards-mainHover">
                                <p>全生命周期管理</p>
                                <p>
                                    <span>
                                        通过工业互联网标识提供的公共标识解析服务，将“信息孤岛”转变成基于统一标识的全流程信息自由流动，实现设计、生产、市场、售后信息的全面数字化与交互。
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- introduction mobile -->
            <div class="introduction-mob">
                <div class="introduction-mob-title">
                    <p>功能介绍</p>
                </div>
                <div class="introduction-mob-cards">
                    <div class="mob-cards-item">
                        <p>一物一码</p>
                        <p>
                            <span>
                                一物一码结算通过为每个商品分配唯一编码，实现商品交易结算，可建立基于唯一标识的多应用模式。基于一物一码结算所产生的交易数据。
                            </span>
                        </p>
                    </div>
                    <div class="mob-cards-item">
                        <p>防伪追溯</p>
                        <p>
                            <span>
                                在产品流通环节中，企业与消费者可基于标识写入的数据对产品质量进行追溯，同时基于标识的高级数字防伪与传统的标签防伪技术相结合，让用户通过扫码便可判断产品真伪。
                            </span>
                        </p>
                    </div>
                    <div class="mob-cards-item">
                        <p>数字营销</p>
                        <p>
                            <span>
                                面向生产制造行业，产品进入流通领域后，在统一编码规范基础上，通过标识解析基础设施获取运营数据、消费数据，赋能企业搭建线上营销闭环；并通过标识大数据，辅助企业开展市场策略。
                            </span>
                        </p>
                    </div>
                    <div class="mob-cards-item">
                        <p>智慧售后</p>
                        <p>
                            <span>
                                基于标识的防伪功能与传统的标签防伪技术相结合，让用户通过扫码便可判断产品真伪。防窜货应用通过对物流环节进行控制，记录产品的每个流转节点，明确商品流向。
                            </span>
                        </p>
                    </div>
                    <div class="mob-cards-item">
                        <p>数字化供应链</p>
                        <p>
                            <span>
                                配套生产企业、仓储物流企业、整机制造企业在标识解析体平台注册、解析、查询，促进生产、运输、使用、服务等环节的数字化高效协同，有效提升企业资源优化配置，实现优化供应链管理。
                            </span>
                        </p>
                    </div>
                    <div class="mob-cards-item">
                        <p>全生命周期管理</p>
                        <p>
                            <span>
                                通过工业互联网标识提供的公共标识解析服务，将“信息孤岛”转变成基于统一标识的全流程信息自由流动，实现设计、生产、市场、售后信息的全面数字化与交互。
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <!-- application -->
        <div class="trace-manage_application trace-manage_common">
            <a name="traceApp" />
            <!-- pc -->
            <div class="application-pc">
                <div class="common-maxWidth">
                    <div class="application-title common-title">
                        <p>应用场景</p>
                    </div>
                    <div class="application-cards">
                        <div class="application-cards-item common-cards-item">
                            <div>
                                <p>制造</p><img :src="applicationZzImg" />
                            </div>
                            <div>
                                <p>电商</p><img :src="applicationDsImg" />
                            </div>
                            <div>
                                <p>农业</p><img :src="applicationLyImg" />
                            </div>
                            <div>
                                <p>食品</p><img :src="applicationSpImg" />
                            </div>
                        </div>
                        <div class="application-cards-item common-cards-item">
                            <div>
                                <p>能源</p><img :src="applicationNyImg" />
                            </div>
                            <div>
                                <p>航天</p><img :src="applicationHtImg" />
                            </div>
                            <div>
                                <p>医疗</p><img :src="applicationYlImg" />
                            </div>
                            <div><img :src="applicationMoreImg" /></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="application-mob">
                <div class="application-mob-title">
                    <p>应用场景</p>
                </div>
                <div class="application-mob-cards">
                    <div class="mob-cards-item">
                        <div>
                            <p>制造</p><img :src="applicationZzImg" />
                        </div>
                        <div>
                            <p>电商</p><img :src="applicationDsImg" />
                        </div>
                    </div>
                    <div class="mob-cards-item">
                        <div>
                            <p>农业</p><img :src="applicationLyImg" />
                        </div>
                        <div>
                            <p>食品</p><img :src="applicationSpImg" />
                        </div>
                    </div>
                    <div class="mob-cards-item">
                        <div>
                            <p>能源</p><img :src="applicationNyImg" />
                        </div>
                        <div>
                            <p>航天</p><img :src="applicationHtImg" />
                        </div>
                    </div>
                    <div class="mob-cards-item">
                        <div>
                            <p>医疗</p><img :src="applicationYlImg" />
                        </div>
                        <div><img :src="applicationMoreImg" /></div>
                    </div>
                </div>
            </div>
        </div>
        <!-- footer -->
        <div class="trace-manage_footer trace-manage_common">
            <a name="traceAngle" />
            <!-- pc -->
            <div class="common-maxWidth footer-pc">
                <div class="footer-title common-title">
                    <p>案例展示</p>
                </div>
                <div class="footer-main">
                    <div class="footer-main-left">
                        <img :src="footerBannerImg" />
                    </div>
                    <div class="footer-main-right">
                        <p>石台富硒地理标志农产品认证追溯</p>
                        <div>
                            <span>
                                石台县作为中国天然富硒区域之一，其优越的生态环境优势使得天然硒米得以种植推广。其硒米具有品质优良、安全性高、营养价值丰富等优良特性。截至2022年，石台县开发富硒水稻种植1万亩，年产富硒稻谷4000吨，加工石台硒米2000吨。为打造地理标志农产品品牌，保障消费者食品安全权益，石台政府于2022年基于平台建设了石台富硒地理标志农产品认证追溯平台。
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- mobile -->
            <div class="footer-mob">
                <div class="footer-mob-title">
                    <p>案例展示</p>
                </div>
                <div class="footer-mob-main">
                    <div class="mob-main-top">
                        <img :src="footerBannerImg" />
                    </div>
                    <div class="mob-main-bottom">
                        <p>石台富硒地理标志农产品认证追溯</p>
                        <div>
                            <span>
                                石台县作为中国天然富硒区域之一，其优越的生态环境优势使得天然硒米得以种植推广。其硒米具有品质优良、安全性高、营养价值丰富等优良特性。截至2022年，石台县开发富硒水稻种植1万亩，年产富硒稻谷4000吨，加工石台硒米2000吨。为打造地理标志农产品品牌，保障消费者食品安全权益，石台政府于2022年基于平台建设了石台富硒地理标志农产品认证追溯平台。
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="js">
export default {
    data() {
        return {
            bannerImg: require("../assets/img/banner.png"),
            bitMapImg: require("../assets/img/bitMap.png"),
            introductionYwymImg: require("../assets/img/introduction-ywym.png"),
            introductionFwsyImg: require("../assets/img/introduction-fwsy.png"),
            introductionYxImg: require("../assets/img/introduction-yx.png"),
            introductionZhshImg: require("../assets/img/introduction-zhsh.png"),
            introductionGylImg: require("../assets/img/introduction-gyl.png"),
            introductionSmzqImg: require("../assets/img/introduction-smzq.png"),
            applicationZzImg: require("../assets/img/application-zz.png"),
            applicationDsImg: require("../assets/img/application-ds.png"),
            applicationLyImg: require("../assets/img/application-ly.png"),
            applicationSpImg: require("../assets/img/application-sp.png"),
            applicationNyImg: require("../assets/img/application-ny.png"),
            applicationHtImg: require("../assets/img/application-ht.png"),
            applicationYlImg: require("../assets/img/application-yl.png"),
            applicationMoreImg: require("../assets/img/application-more.png"),
            footerLeftImg: require("../assets/img/footer-left.png"),
            footerRightImg: require("../assets/img/footer-right.png"),
            footerBannerImg: require("../assets/img/footer-banner.png")
        }
    },
    methods: {
        
    }
}
</script>
<style lang="scss" scoped>
// banner
.trace-manage_banner {
    position: relative;

    .banner-title {
            position: absolute;
            font-weight: 600;
            color: #FFFFFF;
            font-size: 40px;
            letter-spacing: 1px;
            top: calc(50% - 28px);
            left: calc(50% - 252px);
        @media screen and (max-width: 600px) {
            font-size: 23px;
            top: calc(50% - 16px);
            left: calc(50% - 145px);
        }
    }

    img {
        width: 100%;
    }
}

// tip
.trace-manage_tip {
    position: relative;

    .tip-mob {
        display: none;
    }

    .trace-manage_tipTitle {
        width: 1080px;
        background-color: #f4f5f5;
        display: flex;
        border-radius: 4px;
        bottom: -148px;
        left: calc(50% - 540px);
        position: absolute;
    }

    .tipTitle-left-top {
        height: 156px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #eeeeee;
        border-radius: 4px 0 0 0;
        padding: 0 50px;
    }

    .tipTitle-left-top div>p {
        margin: 10px;
        font-weight: bold;
        color: #151515;
        font-size: 22px;
        letter-spacing: 1px;
    }

    .tipTitle-left-bottom {
        height: calc(100% - 156px);
        display: flex;
        justify-content: center;
        align-items: center;
        background: #F5F5F5;
        opacity: 0.85;
        border-radius: 0 0 0 4px;
        padding: 0 50px;
    }

    .tipTitle-left-bottom div>span {
        font-size: 15px;
        font-weight: 400;
        color: #151515;
        line-height: 26px;
        letter-spacing: 1px;
    }

    .tipTitle-right {
        display: flex;
        border-radius: 0 4px 4px 0;
    }

    .tipTitle-right img {
        height: 100%;
    }

    @media screen and (max-width: 600px) {
        .tip-pc {
            display: none;
        }

        .tip-mob {
            display: block;

            .tip-mob-title>.mob-title-top>div {
                width: calc(100% - 40px);
                font-size: 15px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #191919;
                line-height: 21px;
                margin: 20px;
            }

            .tip-mob-title>.mob-title-bottom>div {
                width: calc(100% - 40px);
                font-size: 13px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #151515;
                line-height: 23px;
                margin: 20px;
            }
        }
    }
}

// 公用属性
.common-maxWidth {
    margin: 0 auto;
    width: 1080px;

    .common-title {
        text-align: center;
        height: 56px;
        line-height: 56px;
        font-size: 40px;
        font-weight: 600;
        margin: 50px 25px 25px;
        letter-spacing: 1px;
    }

    .common-cards-item {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .common-cards-item>div {
        position: relative;
    }
}

// hover 属性
.intro-cards-hover {
    position: absolute;
    bottom: 22px;
    width: 340px;
    left: calc(50% - 173px);
    height: 52px;
    background: rgb(180 38 32 / 65%);
    box-shadow: 3px 3px 14px 1px rgb(0 0 0 / 6%);
    border-radius: 0px 0px 4px 4px;
    text-align: center;
    line-height: 52px;

    p {
        font-size: 22px;
        font-weight: 600;
        color: #FFFFFF;
        cursor: pointer;
    }

    &:hover {
        display: none;
    }

    &:hover+.intro-cards-mainHover {
        display: block;
    }
}

.intro-cards-mainHover {
    display: none;
    position: absolute;
    bottom: 22px;
    width: 340px;
    left: calc(50% - 173px);
    height: 200px;
    background: rgb(180 38 32 / 65%);
    box-shadow: 3px 3px 14px 1px rgb(0 0 0 / 6%);
    border-radius: 4px;
    line-height: 200px;

    p {
        &:nth-child(1) {
            width: auto;
            height: 30px;
            font-size: 22px;
            font-weight: 600;
            color: #FFFFFF;
            line-height: 30px;
            margin: 24px auto;
            text-align: center;
        }

        &:nth-child(2) {
            width: 284px;
            height: 100px;
            font-size: 14px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 20px;
            margin: 0 auto;
        }
    }
}

// 介绍
.trace-manage_introduction {
    padding: 0 0 64px 0;
    margin: 220px 0 0 0;

    .introduction-mob {
        display: none;
    }

    @media screen and (max-width: 600px) {
        padding: 0;
        margin: 0;

        .introduction-pc {
            display: none;
        }

        .introduction-mob {
            display: block;

            .introduction-mob-title {
                font-size: 17px;
                font-weight: 600;
                color: #191919;
                text-align: center;
            }

            .introduction-mob-cards {
                .mob-cards-item {
                    background: url('../assets/img/introBack.png');
                    background-repeat: no-repeat;
                    background-size: 100% 100%;

                    p {
                        &:nth-child(1) {
                            width: auto;
                            height: 24px;
                            font-size: 17px;
                            font-weight: 600;
                            color: #B42620;
                            line-height: 24px;
                            text-align: center;
                            padding: 30px 0 25px 0;
                        }

                        &:nth-child(2) {
                            width: calc(100% - 60px);
                            font-size: 14px;
                            font-weight: 400;
                            color: #151515;
                            line-height: 24px;
                            margin: 0 30px;
                            padding: 0 0 50px 0;
                        }
                    }
                }

            }
        }
    }
}

// 应用
.trace-manage_application {
    padding: 64px;
    background: #FDF5F5;

    .application-mob {
        display: none;
    }

    .application-cards-item>div {
        position: relative;
    }

    .application-cards-item>div>p {
        position: absolute;
        top: calc(50% - 32.5px);
        left: calc(50% - 24px);
        height: 65px;
        font-size: 17px;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 65px;
        text-align: center;
        letter-spacing: 1px;
    }

    .application-title {
        margin: 0 25px 25px;
    }

    @media screen and (max-width: 600px) {
        padding: 0;
        margin: 0;
        background: none;

        .application-pc {
            display: none;
        }

        .application-mob {
            display: block;

            .application-mob-title {
                width: 68px;
                height: 24px;
                font-size: 17px;
                font-weight: 600;
                color: #191919;
                line-height: 24px;
                margin: 0 auto;
            }

            .application-mob-cards {
                .mob-cards-item {
                    display: flex;
                    justify-content: space-between;
                }

                .mob-cards-item>div {
                    position: relative;

                    img {
                        width: 100%;
                    }
                }

                .mob-cards-item>div {
                    p {
                        position: absolute;
                        top: calc(50% - 32.5px);
                        left: calc(50% - 24px);
                        width: 48px;
                        height: 65px;
                        font-size: 20px;
                        font-weight: 600;
                        color: #FFFFFF;
                        line-height: 65px;
                        text-align: center;
                        letter-spacing: 1px;
                    }
                }
            }
        }

    }
}

// 尾部
.trace-manage_footer {
    background: url('../assets/img/footer-background.png');
    background-size: cover;
    margin: 0 0 46px 0;

    .footer-main {
        display: flex;
        justify-content: center;

        // &::before {
        //     content: url('../assets/img/footer-left.png');
        //     display: flex;
        //     align-items: center;
        //     padding: 0 40px 0 0;
        //     cursor: pointer;
        // }

        // &::after {
        //     content: url('../assets/img/footer-right.png');
        //     display: flex;
        //     align-items: center;
        //     padding: 0 0 0 50px;
        //     cursor: pointer;
        // }

        .footer-main-left {
            margin: 0 55px 0 0;
        }

        .footer-main-right {
            p {
                width: 500px;
                height: 38px;
                font-size: 22px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #151515;
                line-height: 38px;
                margin: 10px 0 10px 0;
            }
        }

        .footer-main-right>div {
            width: 537px;
            height: 150px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #151515;
            line-height: 30px;
        }
    }

    .footer-mob {
        display: none;
    }

    @media screen and (max-width: 600px) {
        background-image: none;
        margin: 0;
        .footer-pc {
            display: none;
        }

        .footer-mob {
            display: block;
            padding: 20px 0 0;
            .footer-mob-title {
                font-size: 17px;
                font-weight: 600;
                color: #191919;
                text-align: center;
            }

            .footer-mob-main {
                .mob-main-top {
                    text-align: center;
                    img {
                        width: 100%;
                    }
                //     &::before {
                //     content: url('../assets/img/footer-left.png');
                //     position: absolute;
                //     left: 5%;
                //     padding: 10vh 0 0 0;
                //     cursor: pointer;
                // }

                // &::after {
                //     content: url('../assets/img/footer-right.png');
                //     position: absolute;
                //     right: 5%;
                //     padding: 10vh 0 0 0;
                //     cursor: pointer;
                // }
                }

                .mob-main-bottom {
                    p {
                        height: 21px;
                        font-size: 15px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #151515;
                        line-height: 21px;
                        text-align: center;
                        margin: 0 0 10px 0;
                    }
                }

                .mob-main-bottom>div {
                    width: calc(100% - 60px);
                    height: 240px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #151515;
                    line-height: 30px;
                    margin: 0 30px;
                }
            }
        }
    }
}
</style>