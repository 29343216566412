<template>
	<div>
		<qrcode-stream v-if="show" :camera="camera" :torch="torchActive" @decode="onDecode" @init="onInit">
			<div>
				<div class="qr-scanner">
					<div class="box">
						<div class="line"></div>
						<div class="angle"></div>
					</div>
				</div>
			</div>
		</qrcode-stream>
	</div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader';
export default {
    components: {
        QrcodeStream
    },
    data() {
        return {
            torchActive: false,
            camera: 'auto',
        };
    },
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        async onDecode(scanResult) {
			// TODO 逻辑处理或直接返回扫码结果
            this.$emit('getScanResult', scanResult)
        },
        async onInit(promise) {
            // const {
            //     capabilities
            // } = await promise;

            // const TORCH_IS_SUPPORTED = !!capabilities.torch;
            try {
                await promise;
            } catch (error) {
                if (error.name === 'NotAllowedError') {
                    this.$message.error('ERROR');
                } else if (error.name === 'NotFoundError') {
                    this.$message.error('这个设备上没有摄像头');
                } else if (error.name === 'NotSupportedError') {
                    this.$message.error('所需的安全上下文(HTTPS、本地主机)');
                } else if (error.name === 'NotReadableError') {
                    this.$message.error('相机被占用');
                } else if (error.name === 'OverconstrainedError') {
                    this.$message.error('安装摄像头不合适');
                } else if (error.name === 'StreamApiNotSupportedError') {
                    this.$message.error('此浏览器不支持流API');
                }
            }
        },
    },
};
</script>

<style scoped>
.qr-scanner {
    background-image: linear-gradient(0deg,
            transparent 24%,
            rgba(32, 255, 77, 0.1) 25%,
            rgba(32, 255, 77, 0.1) 26%,
            transparent 27%,
            transparent 74%,
            rgba(32, 255, 77, 0.1) 75%,
            rgba(32, 255, 77, 0.1) 76%,
            transparent 77%,
            transparent),
        linear-gradient(90deg,
            transparent 24%,
            rgba(32, 255, 77, 0.1) 25%,
            rgba(32, 255, 77, 0.1) 26%,
            transparent 27%,
            transparent 74%,
            rgba(32, 255, 77, 0.1) 75%,
            rgba(32, 255, 77, 0.1) 76%,
            transparent 77%,
            transparent);
    background-size: 3rem 3rem;
    background-position: -1rem -1rem;
    width: 100%;
    height: 29vh;
    position: relative;
    background-color: #1110;
}

.qr-scanner .box {
    width: 213px;
    height: 213px;
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    border: 0.1rem solid rgba(0, 255, 51, 0.2);
}

.qr-scanner .txt {
    width: 100%;
    height: 35px;
    line-height: 35px;
    font-size: 14px;
    text-align: center;
    margin: 0 auto;
    position: absolute;
    top: 70%;
    left: 0;
}

.qr-scanner .myQrcode {
    text-align: center;
    color: #00ae10;
}

.qr-scanner .line {
    height: calc(100% - 2px);
    width: 100%;
    background: linear-gradient(180deg, rgba(0, 255, 51, 0) 43%, #00ff33 211%);
    border-bottom: 3px solid #00ff33;
    transform: translateY(-100%);
    animation: radar-beam 2s infinite alternate;
    animation-timing-function: cubic-bezier(0.53, 0, 0.43, 0.99);
    animation-delay: 1.4s;
}

.qr-scanner .box:after,
.qr-scanner .box:before,
.qr-scanner .angle:after,
.qr-scanner .angle:before {
    content: '';
    display: block;
    position: absolute;
    width: 3vw;
    height: 3vw;

    border: 0.2rem solid transparent;
}

.qr-scanner .box:after,
.qr-scanner .box:before {
    top: 0;
    border-top-color: #00ff33;
}

.qr-scanner .angle:after,
.qr-scanner .angle:before {
    bottom: 0;
    border-bottom-color: #00ff33;
}

.qr-scanner .box:before,
.qr-scanner .angle:before {
    left: 0;
    border-left-color: #00ff33;
}

.qr-scanner .box:after,
.qr-scanner .angle:after {
    right: 0;
    border-right-color: #00ff33;
}

@keyframes radar-beam {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0);
    }
}
</style>

