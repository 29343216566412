<template>
    <div class="news-card">
        <div class="img-box"><img :src="info.newsImg"></div>
        <div class="content-box">
            <div class="right-box" style="width: 100%;height: 100%;">
                <div class="title" :title="info.title">{{ info.title }}</div>
                <div class="content-text" :title="info.content">{{ info.content }}</div>
                <div class="bottom">
                    <div class="time">{{ info.time }}</div>
                    <div class="view-btn"><a :href="info.link">立即查看<i class="el-icon-arrow-right"></i></a></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NewsCardMob',
    data() {
        return {
            viewBtn: require("../../assets/img/view-btn.png")
        }
    },
    props: {
        info:{}
    },
};
</script>
<style lang="scss" scoped>
    .news-card{
        width: 100%;
        height: 120px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #F2F2F2;
        .img-box{
            width: 90px;
            height: 90px;
            img{
                width: 90px;
                height: 90px;
            }
        }
        .content-box{
            width: calc(100% - 90px);
            padding: 11px 15px 13px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            height: 120px;
            .right-box{
                height: 100%;
                width: 100%;
                .title{
                    width: 100%;
                    height: 34px;
                    font-size: 15px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #151515;
                    line-height: 34px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin-bottom: 3px;
                }
                .content-text{
                    width: 100%;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #151515;
                    line-height: 28px;
                    margin-bottom: 14px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .bottom{
                    width: 100%;
                    height: 17px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .time{
                        height: 17px;
                        font-size: 12px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #151515;
                        line-height: 17px;
                    }
                    .view-btn{
                        a{
                            height: 17px;
                            font-size: 12px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #B42620;
                            line-height: 17px;
                            cursor: pointer;
                            .right-outlined{
                                margin-left: 4px;
                            }
                        }
                    }
                }
            }
        }
    }
</style>
