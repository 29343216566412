<template>
    <div class="img_frame">
        <div
            @mouseout.stop="mouseout"
            @mouseover.stop="mouseover"
            @mousedown.stop="mousedown"
            @mousemove.stop="mousemove"
            @mouseup.stop="mouseup"
            @mouseleave.stop="mouseleave"
            @touchstart="touchstart"
            @touchmove="touchmove"
            @touchend="touchend"
        >
            <img
                v-for="(v, i) in imgs"
                :style="'z-index:' + v.z"
                class="slide-images"
                :key="i"
                :src="imageSrc(v.src)"
                alt=""
                srcset=""
            />
        </div>
        <img v-if="imgs.length > 0" class="base-image" :src="imageSrc(imgs[0].src)" />
        <div class="silder">
            <input
                style="flex: 1"
                @input="change"
                class="range_slider_range"
                type="range"
                :value="max"
                min="0"
                :max="imgs.length - 1"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        item: Object
    },
    watch: {
        'item.value.images': {
            handler(images) {
                this.isAnimation = images.length > 0
                this.imgs = images.map(i => ({
                    src: i,
                    z: 0
                }))
                //默认第一张置顶
                this.maxZIndex = 0
                this.setMax()
            },
            immediate: true
        }
    },
    data() {
        return {
            imgs: [],
            maxZIndex: 0,
            startX: 0,
            endX: 0,
            deltaX: 0,
            max: 0,
            timer: null,
            keyDown: false,
            isAnimation: true,
            touchDown: false
        }
    },
    methods: {
        touchstart(e) {
            e.stopPropagation()
            e.preventDefault()
            this.startX = e.touches[0].clientX
            this.touchDown = true
            if (this.timer != null) {
                clearInterval(this.timer)
                this.timer = null
            }
        },
        touchmove(e) {
            e.stopPropagation()
            e.preventDefault()
            if (this.touchDown) {
                this.endX = e.touches[0].clientX
                this.deltaX = this.endX - this.startX
                const len = this.imgs.length - 1
                if (this.deltaX < 0) {
                    if (this.maxZIndex + 1 <= len) {
                        this.maxZIndex++
                    } else {
                        this.maxZIndex = 0
                    }
                } else {
                    if (this.maxZIndex - 1 >= 0) {
                        this.maxZIndex--
                    } else {
                        this.maxZIndex = len
                    }
                }
                this.setMax()
                this.startX = this.endX
            }
        },
        touchend(e) {
            e.stopPropagation()
            e.preventDefault()
            this.touchDown = false
        },
        change(e) {
            e.stopPropagation()
            e.preventDefault()
            this.max = e.target.value
            this.maxZIndex = this.max
            this.setMax()
        },
        mouseout(e) {
            e.stopPropagation()
            e.preventDefault()
            if (this.timer) {
                clearInterval(this.timer)
                this.timer = null
            }
            this.isAnimation = true
        },
        mouseover(e) {
            e.stopPropagation()
            e.preventDefault()
            this.isAnimation = false
        },
        mousedown(e) {
            e.stopPropagation()
            e.preventDefault()
            this.keyDown = true
            this.startX = e.pageX
        },
        mousemove(e) {
            e.stopPropagation()
            e.preventDefault()
            if (this.keyDown == true) {
                this.endX = e.pageX
                this.deltaX = this.endX - this.startX
                const len = this.imgs.length - 1
                if (this.deltaX < 0) {
                    if (this.maxZIndex + 1 <= len) {
                        this.maxZIndex++
                    } else {
                        this.maxZIndex = 0
                    }
                } else {
                    if (this.maxZIndex - 1 >= 0) {
                        this.maxZIndex--
                    } else {
                        this.maxZIndex = len
                    }
                }
                this.setMax()
                this.startX = e.pageX
            }
        },
        mouseup(e) {
            e.stopPropagation()
            e.preventDefault()
            this.keyDown = false
        },
        mouseleave(e) {
            e.stopPropagation()
            e.preventDefault()
            this.keyDown = false
        },
        setMax() {
            if (this.isAnimation) {
                this.imgs.forEach(m => (m.z = 0))
                this.imgs[this.maxZIndex].z = 99
            }
        },
        imageSrc(src) {
            if (src.startsWith('http')) {
                return src + '?x-image-process=style/width-1024'
            }
            return src
        }
    },
    mounted() {
        this.timer = setInterval(() => {
            if (this.isAnimation) {
                this.maxZIndex = (this.maxZIndex + 1) % this.imgs.length
                this.setMax()
            }
        }, 200)
    }
}
</script>

<style lang="scss" scoped>
@mixin set-height {
    width: 100%;
    min-height: 100px;
    max-height: 1000px;
}

.img_frame {
    position: relative;
    height: 100%;
    @include set-height;
}

.slide-images {
    position: absolute;
    left: 0;
    @include set-height;
}

.base-image {
    z-index: -100;
    @include set-height;
}

.silder {
    width: 100%;
    height: 30px;
    background: red;
    z-index: 999;
    position: absolute;
    bottom: 0;
    background: rgba(255, 255, 255, 0.6);
    display: flex;
}

.range_slider_range {
    cursor: pointer;
}
</style>
