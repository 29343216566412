<template>
    <div class="home-class">
        <div class="banner-box">
            <img :src="bannerImg" class="banner-img">
            <div class="title-input">
                <div>
                    <el-input v-model="code" placeholder="请输入要查询的标识码"></el-input>
                    <img :src="scanIcon" class="scan-icon" @click="showScan = true" />
                </div>
                <div class="search-btn" @click="goSearchResultPage">查询验证</div>
            </div>
        </div>
        <div class="code-data-box">
            <div class="data-title">全国标识解析数据库</div>
            <div class="data-card-box">
                <div v-for="item, index in cardData" :key="index" :class="[ 'card-box', index <= 1 ? 'border-right' : '' ]">
                    <div class="number-box">
                        <span class="number">{{ item.number }}</span>
                        <span class="unit">{{ item.unit }}</span>
                    </div>
                    <div class="text">{{ item.text }}</div>
                </div>
            </div>
            <div class="data-scroll-box">
                <div class="data-scroll-title">
                    <div>标识编码</div>
                    <div>所属企业</div>
                    <div>访问时间</div>
                </div>
            </div>
            <div id="parent" class="parent" @mouseenter="endScroll" @mouseleave="startScroll">
                <div id="child1" class="child">
                    <div class="box" v-for="item, index in scrollData" :key="index">
                        <el-tooltip effect="dark" :content="item.code" placement="top">
                            <div class="code" :title="item.code">{{ item.code }}</div>
                        </el-tooltip>
                        <el-tooltip effect="dark" :content="item.company" placement="top">
                            <div class="company" :title="item.company">{{ item.company }}</div>
                        </el-tooltip>
                        <el-tooltip effect="dark" :content="item.time" placement="top">
                            <div class="time" :title="item.time">{{ item.time }}</div>
                        </el-tooltip>
                    </div>
                </div>
                <div id="child2" class="child"></div>
            </div>
        </div>
        <el-dialog title="请扫码" :visible.sync="showScan" :fullscreen="true">
            <QrCode :show="showScan" @getScanResult="getScanResult"></QrCode>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showScan = false">取 消</el-button>
                <el-button type="primary" @click="showScan = false">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import QrCode from "../QrCode.vue";
let time;
export default {
    name: 'HomeMob',
    mounted(){
        this.getStatisticsAll()
        this.loadScrollData()
        this.time2 = setInterval(() =>{
            this.loadScrollData()
        }, 2000)
        this.scrolls()
    },
    data() {
        return {
            bannerImg: require("../../assets/img/mode-banner-mob.png"),
            code: '',
            time,
            scanIcon: require("../../assets/icon/saoyisao.png"),
            cardData: [
                {
                    number: 187,
                    unit: '家',
                    text: '注册企业',
                    bgImg: require("../../assets/img/code.png")
                },
                {
                    number: 20929,
                    unit: '个',
                    text: '已注册标识',
                    bgImg: require("../../assets/img/search.png")
                },
                {
                    number: 80669,
                    unit: '次',
                    text: '标识查询',
                    bgImg: require("../../assets/img/compony.png")
                },
            ],
            scrollData: [],
            showScan: false,
            page: 0
        }
    },
    methods: {
        loadScrollData(){
            this.page += 1
            this.$axios.post(`fcapi/statisticsScanCode/scanCodeRecordList?page=${this.page}&limit=20&condition=cn88555`).then(res=>{
                if(res&&res.data.code == 0){
                    res.data.data.forEach(element => {
                        this.scrollData.push(
                            {
                                code: element.idisCode,
                                company: element.tenantName,
                                time: element.createTime
                            }
                        )
                    });
                }else{
                    this.$message.error("获取数据失败！")
                }
            })
        },
        scrolls() {
            let parent = document.getElementById('parent');
            let child1 = document.getElementById('child1');
            let child2 = document.getElementById('child2');
            child2.innerHTML = child1.innerHTML;
            this.time = setInterval(() => {
                parent.scrollTop += 1;
            }, 20);
        },
        endScroll(){
            clearInterval(this.time)
        },
        startScroll(){
            this.scrolls()
        },
        goSearchResultPage(){
            this.$router.push({
                path: '/searchResult',
                query: { code: this.code }
            })
        },
        getScanResult(code){
            this.code = code
            this.goSearchResultPage()
        },
        getStatisticsAll(){
            this.$axios.get("fcapi/console/queryStatisticsAll").then(res=>{
                if(res&&res.data.code == 200){
                    this.cardData[0].number = res.data.data.tenantSum
                    this.cardData[0].text = '已注册' + res.data.data.tenantSum + '家企业'
                    this.cardData[1].number = res.data.data.createSum
                    this.cardData[1].text = '已注册' + res.data.data.createSum + '个标识'
                    this.cardData[2].number = res.data.data.scanSum
                    this.cardData[2].text = '已进行' + res.data.data.scanSum + '次标识查询'
                }else{
                    this.$message.error("获取数据失败！")
                }
            })
        }
    },
    components: {
        QrCode
    }
};
</script>

<style scoped lang="scss">
    ::v-deep .el-dialog__footer{
        margin-top: 300px;
    }
    .home-class{
        width: 100%;
        ::-webkit-scrollbar {
            width:2px;
        }
        /* 滚动槽 */
        ::-webkit-scrollbar-track {
            -webkit-box-shadow:inset006pxrgba(0,0,0,0.3);
            border-radius:1px;
        }
        /* 滚动条滑块 */
        ::-webkit-scrollbar-thumb {
            border-radius:11px;
            background:rgba(0,0,0,0.1);
            -webkit-box-shadow:inset006pxrgba(0,0,0,0.5);
        }
        .banner-box{
            width: 100%;
            position: relative;
            margin-bottom: 30px;
            .banner-img{
                width: 100%;
            }
            .title-input{
                position: absolute;
                top: 50%;
                left: 50%;
                width: 75%;
                transform: translate(-50%,-50%);
                text-align: center;
                .el-input{
                    width: 100%;
                }
                ::v-deep .el-input__inner{
                    border-radius: 20px;
                    border-color: #B42620;
                    padding: 1px 10px;
                    height: 40px;
                    width: 100%;
                }
                .scan-icon{
                    width: 18px;
                    height: 18px;
                    position: absolute;
                    right: 20px;
                    bottom: 67px;
                    cursor: pointer;
                }
                .search-btn{
                    color: white;
                    margin: 0 auto;
                    width: 100px;
                    height: 32px;
                    line-height: 32px;
                    background-color: #B42620;
                    text-align: center;
                    cursor: pointer;
                    margin-top: 24px;
                }
            }
        }
        .code-data-box{
            width: 100%;
            .data-title{
                width: 100%;
                height: 24px;
                font-size: 17px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #191919;
                line-height: 24px;
                text-align: center;
                margin-bottom: 20px;
            }
            .data-card-box{
                display: flex;
                justify-content: space-between;
                margin-bottom: 30px;
                .card-box{
                    width: 33.3%;
                    height: 67px;
                    padding: 6px 0px;
                    .number-box{
                        text-align: center;
                        .number{
                            height: 29px;
                            font-size: 21px;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            color: #B42620;
                            line-height: 29px;
                        }
                        .unit{
                            height: 16px;
                            font-size: 11px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #B42620;
                            line-height: 16px;
                        }
                    }
                    .text{
                        margin-top: 6px;
                        height: 20px;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #151515;
                        line-height: 20px;
                        text-align: center;
                    }
                }
                .border-right{
                    border-right: 1px solid #B42620;
                }
            }
        }
        .data-scroll-box{
            box-sizing: border-box;
            margin: 0px 15px;
            padding: 0px 10px;
            height: 60px;
            background-color: #F2F2F2;
            .data-scroll-title{
                display: flex;
                justify-content: space-between;
                height: 60px;
                div{
                    width: 33.3%;
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #151515;
                    height: 60px;
                    display: flex;
                    align-items: center;
                }
            }
        }
        .parent {
            width: 100%;
            height: 450px;
            margin: 0 auto;
            overflow: auto;
        }
        /*设置的子盒子高度大于父盒子，产生溢出效果*/
        .child {
            height: auto;
            .box{
                margin: 0px 15px;
                padding: 0px 10px;
                height: 50px;
                display: flex;
                font-size: 12px;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid #F2F2F2;
                div{
                    width: calc((100% - 60px) / 3);
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #151515;
                    margin-right: 30px;
                }
                .code{
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .company{
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
</style>
