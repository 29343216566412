<template>
    <div class="header-class">
        <span>{{ titie }}</span>
        <img :src="moreImg" class="moreimg" @click="drawer = !drawer">
        
        <el-drawer :visible.sync="drawer" direction="rtl" size="60%">
            <div class="title-item" @click="changePage('/')">首页</div>
            <div class="title-item" @click="changePage('/traceManage')">国家标识解析追溯防伪平台</div>
            <div class="title-item" @click="changePage('/interpret')">政策解读</div>
        </el-drawer>
    </div>
</template>

<script>
export default {
    name: 'HeaderMob',
    data() {
        return {
            moreImg: require("../../assets/icon/more.png"),
            titie: '国家工业互联网标识查验中心',
            drawer: false
        }
    },
    methods: {
        changePage(path){
            this.$router.push(path)
        }
    },
};
</script>

<style scoped lang="scss">
    .header-class{
        width: 100%;
        height: 38px;
        background-color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        .moreimg{
            position: absolute;
            width: 17px;
            height: 17px;
            right: 15px;
            cursor: pointer;
        }
    }
</style>
