<template>
    <div class="footer-class">
        <div class="order-box">
            <div class="order-title">预约试用</div>
            <el-input v-model="addInfo.companyName" placeholder="请输入您公司名称" class="compony-name-input" />
            <el-input v-model="addInfo.name" placeholder="请输入您的姓名" class="other-input mag-right" />
            <el-input v-model="addInfo.position" placeholder="请输入您的职位" class="other-input" />
            <el-input v-model="addInfo.phoneNumber" placeholder="请输入您的手机号码" class="other-input mag-right" />
            <el-input v-model="addInfo.email" placeholder="请输入您的邮箱" class="other-input" />
            <div class="submitBtn" @click="sunmitInfo">预约试用</div>
        </div>
        <div class="links-box">
            <div class="links-title">友情链接</div>
            <a href="https://www.miit.gov.cn/" class="link-item">中华人民共和国工业和信息化部</a>
            <a href="http://www.caict.ac.cn/" class="link-item">中国信息通信研究院</a>
            <a href="https://www.citln.cn/" class="link-item">中国工业互联网顶级节点</a>
        </div>
        <div class="contact-us">
            <div class="contact-title">联系我们</div>
            <div class="phone-number">咨询热线：400-106-2918</div>
            <div class="img-box"><img :src="weixinQrcode"></div>
            <div class="weixin-title">官方微信</div>
        </div>
        <div class="copy-right">
            <div class="line">Copyright 2022-2027 fc3de.com</div>
            <div class="line">南京复创智能制造技术有限公司版权所有 <a href="http://beian.miit.gov.cn/">苏ICP备2022027126号-2</a></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FooterWeb',
    data() {
        return {
            weixinQrcode: require("../../assets/img/weixin-qrcode.png"),
            addInfo: {
                companyName: '',
                name:'',
                position: '',
                phoneNumber: '',
                email: ''
            }
        }
    },
    methods:{
        sunmitInfo(){
            if(!this.addInfo.companyName || !this.addInfo.name || !this.addInfo.position || !this.addInfo.phoneNumber || !this.addInfo.email){
                this.$message.error('需要填写的信息不全，请检查！')
                return
            }
            var phoneNumberReg = /^1[3-9]\d{9}$/
            if(!phoneNumberReg.test(this.addInfo.phoneNumber)){
                this.$message.error('手机号码格式不正确！')
                return
            }
            var emailReg = /^([a-zA-Z\d][\w-]{2,})@(\w{2,})\.([a-z]{2,})(\.[a-z]{2,})?$/
            if(!emailReg.test(this.addInfo.email)){
                this.$message.error('邮箱格式不正确！')
                return
            }
            this.$axios.post('fcapi/contactUs/addItem',{
                tenantId: 1,
                companyName: this.addInfo.companyName,
                name: this.addInfo.name,
                contact: this.addInfo.phoneNumber,
                position: this.addInfo.position,
                mail: this.addInfo.email
            }).then(res => {
                if(res&&res.data.code == 200){
                    this.$message.success("预约成功！请耐心等候消息。")
                }else{
                    this.$message.error("预约失败！")
                }
            })
        }
    }
};
</script>

<style scoped lang="scss">
    .footer-class{
        margin-top: 10px;
        padding: 20px 15px;
        text-align: center;
        .order-box{
            margin-bottom: 32px;
            ::v-deep .el-input__inner{
                height: 32px !important;
                line-height: 32px !important;
            }
            .order-title{
                height: 24px;
                font-size: 17px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #191919;
                line-height: 24px;
                margin-bottom: 20px;
            }
            .compony-name-input{
                width: 100%;
                margin-bottom: 10px;
            }
            .other-input{
                width: calc((100% - 15px) / 2);
                margin-bottom: 10px;
            }
            .mag-right{
                margin-right: 15px;
            }
            .submitBtn{
                width: 100% !important;
                width: 345px;
                height: 32px;
                font-size: 15px;
                color: #ffffff;
                line-height: 32px;
                background: #B42620;
                border-radius: 2px;
                text-align: center;
                cursor: pointer;
            }
        }
        .links-box{
            margin-bottom: 32px;
            .links-title{
                height: 24px;
                font-size: 17px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #191919;
                line-height: 24px;
                margin-bottom: 20px;
            }
            .link-item{
                width: 100%;
                display: block;
                margin-bottom: 16px;
                color: #B42620;
                text-decoration: underline;
            }
        }
        .contact-us{
            margin-bottom: 32px;
            .contact-title{
                height: 24px;
                font-size: 17px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #191919;
                line-height: 24px;
                margin-bottom: 20px;
            }
            .phone-number{
                width: 100%;
                text-align: center;
                height: 20px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #151515;
                line-height: 20px;
                margin-bottom: 20px;
            }
            .img-box{
                margin-bottom: 8px;
                img{
                    width: 108px;
                    height: 108px;
                }
            }
            .weixin-title{
                width: 100%;
                text-align: center;
                height: 20px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #151515;
                line-height: 20px;
            }
        }
        .copy-right{
            margin-bottom: 2px;
            .line{
                width: 100%;
                text-align: center;
                height: 17px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #151515;
                line-height: 17px;
                a {
                    color: #151515;
                    text-decoration: none;
                }
                a:hover {
                    color: #ba2636;
                    text-decoration: underline;
                }
            }
        }
    }
</style>
