<template>
    <div class="home-class">
        <div class="banner-box">
            <img :src="bannerImg">
            <div class="title-input">
                <div class="banner-title">国家工业互联网标识追溯防伪平台查验中心</div>
                <el-input v-model="code" placeholder="请输入要查询的标识码">
                    <template #append>
                        <div class="mark-box"></div>
                        <div class="icon-box" @click="goSearchResultPage">
                            <svg t="1658469896542" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4232" id="mx_n_1658469896546" width="200" height="200"><path d="M680.728354 651.326209c121.274064-137.208988 116.320239-346.908988-14.939247-478.166427-136.444579-136.433322-357.662913-136.433322-494.094188 0-136.443555 136.442532-136.443555 357.661889 0 494.105445 131.241067 131.253346 340.927763 136.204102 478.149031 14.942317l265.63187 265.63187 30.889521-30.877241L680.728354 651.326209zM649.273968 622.002346l-28.658713 28.668946c-120.345925 105.622596-303.678394 101.031021-418.524049-13.812587-119.651101-119.651101-119.651101-313.648466 0-433.299567C321.742307 83.909062 515.740696 83.909062 635.39282 203.569372 750.211868 318.380234 754.826979 501.656421 649.273968 622.002346z" p-id="4233" fill="#ffffff"></path></svg>
                        </div>
                    </template>
                </el-input>
            </div>
        </div>
        <div class="code-data-box">
            <div class="data-title">全国标识解析数据库</div>
            <div class="data-card-box">
                <CardItem v-for="(item, index) in cardData" :key="index" :cardData="item"></CardItem>
            </div>
            <div class="data-scroll-box">
                <div class="data-scroll-title">
                    <div>标识编码</div>
                    <div>所属企业</div>
                    <div>访问时间</div>
                </div>
            </div>
            <div id="parent" class="parent" @mouseenter="endScroll" @mouseleave="startScroll">
                <div id="child1" class="child">
                    <div class="box" v-for="item,index in scrollData" :key="index">
                        <el-tooltip effect="dark" :content="item.code" placement="top">
                            <div>{{ item.code }}</div>
                        </el-tooltip>
                        <el-tooltip effect="dark" :content="item.company" placement="top">
                            <div>{{ item.company }}</div>
                        </el-tooltip>
                        <el-tooltip effect="dark" :content="item.time" placement="top">
                            <div>{{ item.time }}</div>
                        </el-tooltip>
                    </div>
                </div>
                <div id="child2" class="child"></div>
            </div>
        </div>
    </div>
</template>

<script>
import CardItem from "../data-card-item/data-card-item-web.vue";
let time;
export default {
    name: 'HomeWeb',
    mounted(){
        this.getStatisticsAll()
        this.loadScrollData()
        this.time2 = setInterval(() =>{
            this.loadScrollData()
        }, 2000)
        this.scrolls()
    },
    destroyed(){
        clearInterval(this.time2)
        clearInterval(this.time)
    },
    data() {
        return {
            count:20,
            code: '',
            time,
            time2: null,
            bannerImg: require("../../assets/img/home-banner.png"),
            searchImg: require("../../assets/icon/search.png"),
            cardData: [
                {
                    number: 187,
                    unit: '家',
                    text: '已注册187家企业',
                    bgImg: require("../../assets/img/code.png")
                },
                {
                    number: 20929,
                    unit: '个',
                    text: '已注册20929个标识',
                    bgImg: require("../../assets/img/search.png")
                },
                {
                    number: 80669,
                    unit: '次',
                    text: '已进行1841181次标识查询',
                    bgImg: require("../../assets/img/compony.png")
                },
            ],
            scrollData: [],
            page: 0
        }
    },
    methods: {
        loadScrollData(){
            this.page += 1
            this.$axios.post(`fcapi/statisticsScanCode/scanCodeRecordList?page=${this.page}&limit=20&condition=cn88555`).then(res=>{
                if(res&&res.data.code == 0){
                    res.data.data.forEach(element => {
                        this.scrollData.push(
                            {
                                code: element.idisCode,
                                company: element.tenantName,
                                time: element.createTime
                            }
                        )
                    });
                }else{
                    this.$message.error("获取数据失败！")
                }
            })
        },
        scrolls() {
            let parent = document.getElementById('parent');
            let child1 = document.getElementById('child1');
            let child2 = document.getElementById('child2');
            child2.innerHTML = child1.innerHTML;
            this.time = setInterval(() => {
                parent.scrollTop += 1;
            }, 20);
        },
        endScroll(){
            clearInterval(this.time)
        },
        startScroll(){
            this.scrolls()
        },
        goSearchResultPage(){
            this.$router.push({
                path: '/searchResult',
                query: { code: this.code }
            })
        },
        getStatisticsAll(){
            this.$axios.get("fcapi/console/queryStatisticsAll").then(res=>{
                if(res&&res.data.code == 200){
                    this.cardData[0].number = res.data.data.tenantSum
                    this.cardData[0].text = '已注册' + res.data.data.tenantSum + '家企业'
                    this.cardData[1].number = res.data.data.createSum
                    this.cardData[1].text = '已注册' + res.data.data.createSum + '个标识'
                    this.cardData[2].number = res.data.data.scanSum
                    this.cardData[2].text = '已进行' + res.data.data.scanSum + '次标识查询'
                }else{
                    this.$message.error("获取数据失败！")
                }
            })
        }
    },
    components: {
        CardItem
    }
};
</script>

<style scoped lang="scss">
    .home-class{
        min-width: 1080px;
        width: 100%;
        margin-bottom: 60px;
        ::-webkit-scrollbar {
            width:4px;
        }
        /* 滚动槽 */
        ::-webkit-scrollbar-track {
            -webkit-box-shadow:inset006pxrgba(0,0,0,0.3);
            border-radius:1px;
        }
        /* 滚动条滑块 */
        ::-webkit-scrollbar-thumb {
            border-radius:11px;
            background:rgba(0,0,0,0.1);
            -webkit-box-shadow:inset006pxrgba(0,0,0,0.5);
        }
        .banner-box{
            width: 100%;
            position: relative;
            margin-bottom: 64px;
            img{
                width: 100%;
            }
            .title-input{
                position: absolute;
                top: 50%;
                left: 50%;
                width: 783px;
                transform: translate(-50%,-50%);
                .banner-title{
                    height: 56px;
                    font-size: 40px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #FFFFFF;
                    line-height: 56px;
                    letter-spacing: 1px;
                    margin-bottom: 54px;
                }
                ::v-deep .el-input__inner{
                    height: 54px;
                    border-top-left-radius: 27px;
                    border-bottom-left-radius: 27px;
                    padding: 1px 20px;
                    height: 52px;
                }
                ::v-deep .el-input-group__append{
                    position: relative;
                    right: 1px;
                    border-left: none;
                    background-color: #FFFFFF;
                    border-top-right-radius: 27px;
                    border-bottom-right-radius: 27px;
                }
                .mark-box{
                    width: 2px;
                    height: calc(100% - 2px);
                    position: relative;
                    right: 21px;
                    background-color: #ffffff;
                }
                .icon-box{
                    width: 100px;
                    height: 44px;
                    position: relative;
                    left: 16px;
                    background: #B42620;
                    border-radius: 35px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    svg{
                        height: 35px;
                        width: 35px;
                    }
                }
            }
        }
        .code-data-box{
            width: 1080px;
            margin: 0 auto;
            .data-title{
                text-align: center;
                height: 56px;
                font-size: 40px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #151515;
                line-height: 56px;
                letter-spacing: 1px;
                margin-bottom: 40px;
            }
            .data-card-box{
                display: flex;
                justify-content: space-between;
                margin-bottom: 48px;
            }
            .data-scroll-box{
                width: 1080px;
                margin: 0 auto;
                .data-scroll-title{
                    width: 100%;
                    display: flex;
                    background: #F2F2F2;
                    align-items: center;
                    justify-content: space-between;
                    height: 60px;
                    div{
                        padding-left: 40px;
                        box-sizing: border-box;
                        width: 320px;
                    }
                }
            }
            .parent {
                width: 1080px;
                height: 450px;
                margin: 0 auto;
                overflow: auto;
            }
            .child {
                height: auto;
            }
            .box{
                height:50px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid #F2F2F2;
                div{
                    padding-left: 40px;
                    box-sizing: border-box;
                    width: 320px;
                }
            }
        }
    }
</style>
