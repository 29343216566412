<template>
    <div class="mc-wrap f14">
        <el-row
            v-for="(row, idx) in item.value.rows"
            :key="idx"
            style="padding:10px 0;display: flex;justify-content: space-between;"
        >
            <el-col
                v-for="(config, i) in row"
                :key="i"
                :span="7 * config.size"
                style="box-shadow:rgb(0 0 0 / 15%) 0px 1px 5px 0px;border-radius: 10px;"
            >
                <template-stylebox-item :config="config" :code="code"></template-stylebox-item>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import TemplateStyleboxItem from './TemplateStyleboxItem'
export default {
    props: {
        item: Object,
        code: Object
    },
    components: {
        TemplateStyleboxItem
    }
}
</script>

<style scoped>
.mc-wrap {
    padding: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}
</style>
