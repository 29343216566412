<template>
    <div class="mc-wrap mc-info">
        <special-title title="保质期"></special-title>
        <div v-if="!shelfLife.beyondShelfLife" style="flex-grow: 2;">
            <div class="flex-sb" style="margin-top:10px">
                <div style="font-size: 12px;">
                    <span style="color: #4970e0;">{{ shelfLife.total }}</span
                    >天数, 剩余 <span style="color: #4970e0;">{{ shelfLife.remains }}</span
                    >天数
                </div>
                <div style="font-size: 10px">{{ expireDate }}过期</div>
            </div>
            <el-progress
                class="productInfo-progress"
                :stroke-width="8"
                :percentage="shelfLife.percentage"
                stroke-linecap="square"
            ></el-progress>
        </div>
        <div v-else>
            <div style="font-size: 16px; color: #9A9A9A">已过期</div>
        </div>
    </div>
</template>

<script>
import { format } from '../../utils/date-utils'
import SpecialTitle from './SpecialTitle'
export default {
    components: {
        SpecialTitle
    },
    props: {
        item: Object,
        code: Object,
        batch: Object
    },
    computed: {
        shelfLife() {
            const oneDay = 24 * 60 * 60 * 1000
            const startDate = new Date(this.batch.productionDate.substring(0, 10)) / oneDay
            const endDate = new Date(this.batch.beyondShelfLifeDate.substring(0, 10)) / oneDay
            const today = new Date(format(new Date(), 'yyyy-MM-dd')) / oneDay
            const res = {}
            res.total = endDate - startDate + 1
            res.beyondShelfLife = today > endDate
            if (res.beyondShelfLife) {
                res.overTime = today - endDate
                res.percentage = 100
            } else {
                res.remains = endDate - today + 1
                if (today - startDate > 0) {
                    res.percentage = Math.floor(((today - startDate) / res.total) * 100)
                } else {
                    res.percentage = 0
                }
            }
            return res
        },
        expireDate() {
            let date = new Date(this.batch.beyondShelfLifeDate.substring(0, 10))
            return format(date, 'yyyy年MM月dd日')
        }
    }
}
</script>

<style lang="scss" scoped>
//产品信息
.mc-info {
    background-color: #fff;
    text-align: left;
}

.productInfo-progress {
    margin-top: 10px;

    .el-progress-bar__outer {
        height: 30px;
    }
}

::v-deep .el-progress__text {
    color: #4970e0;
    margin-left: 20px;
}

::v-deep .el-progress-bar__inner {
    background-image: linear-gradient(#4a70e0, #4ec9ef);
}
</style>
