<template>
    <div class="interpret-page">
        <div class="banner-box">
            <img :src="bannerImg">
            <div class="title-input">
                <div class="banner-title">政策解读</div>
            </div>
        </div>
        <div class="body-box">
            <div>
                <NewsCardMob v-for="(item, index) in newsCardData" :key="index" :info="item"></NewsCardMob>
            </div>
        </div>
    </div>
</template>

<script>
import NewsCardMob from '../news-card/news-card-mob.vue'

export default {
    name: 'InterpretMob',
    data() {
        return {
            bannerImg: require("../../assets/img/interpret-banner-mob.png"),
            currentPage: 1,
            pageSize: 10,
            newsCardData: [
                {
                    newsImg: require("../../assets/img/news-img1.jpg"),
                    title:'产业赋能',
                    content: '多维视角看工业互联网平台发展质量',
                    time: '2022-07-29 17:00',
                    link: 'http://mp.weixin.qq.com/s?__biz=Mzg5Nzc5MTc4Nw==&mid=2247483740&idx=1&sn=9a3c490966c62eb6b630427efaa452e6&chksm=c06d2318f71aaa0ef87f20774a805b99396154c2b7f0ac9f33b12349b9038757feea23a6333c&mpshare=1&scene=23&srcid=0803KCZNPobCxt74NEUEaWZ9&sharer_sharetime=1659518625646&sharer_shareid=921674d2eb525a624f5204d0cfb3214e#rd'
                },
                {
                    newsImg: require("../../assets/img/news-img2.jpg"),
                    title:'要闻聚焦',
                    content: '工信部：我国工业互联网产业规模超过万亿元',
                    time: '2022-07-25 10:00',
                    link: 'http://mp.weixin.qq.com/s?__biz=Mzg5Nzc5MTc4Nw==&mid=2247483724&idx=1&sn=d6e287f869c6975e52242b2cff513801&chksm=c06d2308f71aaa1ecb0bc9bc46d9c6a372d2a58302d0f1ba0963ca469b6c8afe2351d9c90f9a&mpshare=1&scene=23&srcid=0803vSssWVYeC2poyQLRsgFw&sharer_sharetime=1659518655723&sharer_shareid=921674d2eb525a624f5204d0cfb3214e#rd'
                },
                {
                    newsImg: require("../../assets/img/news-img3.png"),
                    title:'复说政策',
                    content: '工信部印发《工业互联网专项工作组2022年工作计划》（附图解）',
                    time: '2022-07-15 16:00',
                    link: 'http://mp.weixin.qq.com/s?__biz=Mzg5Nzc5MTc4Nw==&mid=2247483706&idx=1&sn=f08b8d5ce57944ddc28644c8ef0dcbed&chksm=c06d237ef71aaa68a846779f9f676e96f8a1224fbb37ec57e528b319abde675faff8874e1775&mpshare=1&scene=23&srcid=0803TX1JbzzijWyA8PKkUd3s&sharer_sharetime=1659518665318&sharer_shareid=921674d2eb525a624f5204d0cfb3214e#rd'
                },
                {
                    newsImg: require("../../assets/img/news-img4.png"),
                    title:'一图读懂',
                    content: '复创智云全产业链质量追溯防伪平台',
                    time: '2022-08-03',
                    link: 'https://mp.weixin.qq.com/s?__biz=Mzg5Nzc5MTc4Nw==&tempkey=MTE3N19kU0VQcXc3WVo0Yi9rL3hLZzE3SkZQTHBPZFZGQWFFNXc0b0h0bEVPbFlRYjBwTlhiMWhrOHZwN2Z4eUd5dVN0Unc5VTJZZlh2aWVGQmlBanN2OVVjeC05SmxGcmJSZFc5Yk9UTjg1NW1MY2FVdm95emtyV0lEOVp1bFlycWh0WUxWMlcyeEJLaTBLY0Qwcl9VNmZLcWdoenpqWEtVTGNiMkhvUnFnfn4%3D&chksm=406d2337771aaa21d689cda0cef19d8326e7c2480d6419ee8068bccd50a8194867d6e1d26341&mpshare=1&scene=1&srcid=0803qEJ6RdIWhvMMbsIF0iZi&sharer_sharetime=1659517410178&sharer_shareid=615b341cd6df16bda5b427be24d67a53#wechat_redirect'
                },
            ]
        }
    },
    components: {
        NewsCardMob
    },
    methods: {
        onPageChange(pageIndex){
            this.currentPage = pageIndex
        },
        onSizeChange(pageSize){
            this.pageSize = pageSize
        }
    },
};
</script>
<style lang="scss" scoped>
    .interpret-page{
        width: 100%;
        .banner-box{
            width: 100%;
            position: relative;
            background-color: #F7F7F7;
            img{
                width: 100%;
            }
            .title-input{
                position: absolute;
                top: 50%;
                left: 50%;
                width: 100%;
                transform: translate(-50%,-50%);
                .banner-title{
                    height: 32px;
                    font-size: 23px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #FFFFFF;
                    line-height: 32px;
                    letter-spacing: 1px;
                    text-align: center;
                }
            }
        }
        .body-box{
            padding: 0px 15px;
        }
    }
</style>
