<template>
    <div class="mc-wrap mc-info">
        <special-title title="标识码"></special-title>
        <h3 id="caict_collectParam_is" style="font: 20px; font-weight: bold; word-break:break-all;text-align: center;">
            {{ code.idisCode }}
        </h3>
        <div class="box">
            <span id="caict_logo" style="width:16px;position: relative;top:3px;right:4px"></span>
            <p>国家工业互联网标识</p>
        </div>
    </div>
</template>

<script>
import Badge from '../../assets/img/code-information-badge.png'
import Badge3 from '../../assets/img/code-information-badge-3.png'
import SpecialTitle from './SpecialTitle'
export default {
    components: {
        SpecialTitle
    },
    props: {
        code: Object
    },
    mounted() {
        const s = document.createElement('script')
        s.type = 'text/javascript'
        s.src = 'https://dms.citln.cn/trust/static/js/caict_logo_id_col.js?id=3a36172b-d04d-4076-8f32-ce15b68641bf'
        s.id = 'caict_logo_id_col'
        s.async = true
        document.body.appendChild(s)
    },
    data() {
        return {
            Badge,
            Badge3
        }
    }
}
</script>

<style lang="scss" scoped>
//产品信息
.mc-info {
    background-color: #fff;
    text-align: left;
}

.badge {
    height: 20px;
    margin: 5px 5px 0 0;
}
.box {
    width: 216px;
    height: 26px;
    background-color: rgb(230, 240, 248);
    line-height: 26px;
    text-align: center;
    border-radius: 20px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #4970e0;
    font-size: 12px;
}
</style>
