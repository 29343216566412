<template>
    <div
        class="stylebox-item"
        :style="{
            backgroundColor: config.backcolor,
            backgroundImage: config.backimage ? `url(${imageSrc(config.backimage)})` : ''
        }"
    >
        <i
            v-show="config.fonticon"
            :class="[config.fonticon, 'image']"
            :style="{
                color: config.forecolor
            }"
        ></i>
        <img v-show="config.imgIcon" :src="config.imgIcon" class="imgIcon" />
        <div
            v-show="config.title"
            class="title"
            :style="{
                textAlign: config.titleAlign,
                color: config.forecolor
            }"
        >
            {{ config.title }}
        </div>
    </div>
</template>

<style lang="scss" scoped>
.stylebox-item {
    // margin: 2px;
    height: 100px;
    border-radius: 10px;
    background-color: #ffffff;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 40px;
    line-height: 40px;
}

.title {
    margin: 2px 0 0 0;
}
.imgIcon {
    // border-radius: 50%;
    width: 50px;
    height: 50px;
    display: block;
}
</style>

<script>
export default {
    props: {
        config: Object
    },
    methods: {
        imageSrc(src) {
            return src + '?x-image-process=style/width-1024'
        }
    }
}
</script>
