<template>
    <div class="search-result">
        <SearchResultWeb v-if="isWeb"></SearchResultWeb>
        <SearchResultMob v-if="!isWeb"></SearchResultMob>
    </div>
</template>

<script>
import { getPcOrMob } from '../utils/utils'
import SearchResultMob from '../components/search-result/search-result-mob.vue'
import SearchResultWeb from '../components/search-result/search-result-web.vue'

export default {
    name: 'SearchResult',
    mounted() {
        setInterval(() => {
            this.isWeb = getPcOrMob()
        }, 100)
    },
    data() {
        return {
            isWeb: true
        }
    },
    components: {
        SearchResultMob, SearchResultWeb
    },
};
</script>
<style lang="scss" scoped>
    .search-result{
        width: 100%;
    }
</style>
