<template>
  <div class="home-page">
    <HomeWeb v-if="isWeb"></HomeWeb>
    <HomeMob v-if="!isWeb"></HomeMob>
  </div>
</template>

<script>
import { getPcOrMob } from '../utils/utils'
import HomeWeb from '../components/home/home-web.vue'
import HomeMob from '../components/home/home-mob.vue'

export default {
  name: 'HomeView',
  mounted() {
    setInterval(() => {
        this.isWeb = getPcOrMob()
    }, 100)
  },
  data() {
    return {
      isWeb: true
    }
  },
  components: {
    HomeWeb, HomeMob
  },
};
</script>
<style lang="scss" scoped>
  .home-page{
    width: 100%;
  }
</style>
