<template>
    <div class="mc-info mc-wrap">
        <special-title title="产品条码"></special-title>
        <!-- <h3 style="font-weight: bold; flex-shrink: 0; width: 80px; margin-right: 15px"></h3> -->
        <div style="text-align: center">
            <img alt="条形码" class="productInfo-barcode" />
        </div>
    </div>
</template>

<script>
import JsBarcode from 'jsbarcode'
import SpecialTitle from './SpecialTitle'
export default {
    components: {
        SpecialTitle
    },
    props: {
        item: Object,
        code: Object
    },
    watch: {
        'code.barCode': {
            handler: function() {
                this.updateBarcode()
            }
        }
    },
    methods: {
        updateBarcode() {
            JsBarcode('.productInfo-barcode', this.code.barCode, {
                format: 'CODE128',
                text: this.code.barCode,
                displayValue: true,
                textPosition: 'bottom',
                width: 1,
                height: 30,
                fontSize: 12
            })
        }
    },
    mounted() {
        this.updateBarcode()
    }
}
</script>

<style lang="scss" scoped>
//产品信息
.mc-info {
    background-color: #fff;
    text-align: left;
}
</style>
