<template>
    <!--产品介绍-->
    <div class="mc-wrap f14 hoverStyle">
        <special-title title="产品介绍" enTitle="PRODUCTION INTRODUCTION"></special-title>
        <div class="mt12 c-444D53 lh20 mc-wrap-cont" style="white-space: pre-wrap">{{ product.remark }}</div>
    </div>
</template>

<script>
import SpecialTitle from './SpecialTitle'
export default {
    props: {
        product: Object
    },
    components: {
        SpecialTitle
    },
    methods: {}
}
</script>
<style lang="scss" scoped>
.mc-wrap {
    padding: 15px !important;
}
.mc-wrap-cont {
    text-align: left;
    word-break: break-all;
    word-wrap: break-word;
}
</style>
