<template>
    <div class="mc-wrap-title">
        <span class="verticalLine">|</span>
        <span class="title">{{ title }}</span>
        <!-- <span class="en">{{ enTitle }}</span> -->
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        enTitle: String
    }
}
</script>

<style scoped>
.mc-wrap-title {
    text-align: left;
}
.title {
    font-size: 16px;
    font-weight: bold;
    color: #1c2023;
}
.en {
    font-size: 11px;
    font-weight: bold;
    color: #c9c9c9;
    border-bottom: 3px solid #c9c9c9;
    top: -3px;
    position: relative;
    margin-left: 5px;
}
.verticalLine {
    width: 2px;
    height: 14px;
    background: #4970e0;
    margin-right: 10px;
}
</style>
