<template>
    <div class="mc-wrap mc-info">
        <special-title title="产品价格"></special-title>
        <div class="currency-price">
            <span><span style="font-size:16px;margin-right:5px">￥</span>{{ price }}</span>
        </div>
    </div>
</template>

<script>
import SpecialTitle from './SpecialTitle'
export default {
    components: {
        SpecialTitle
    },
    props: {
        item: Object,
        code: Object
    },
    computed: {
        price() {
            if (!this.code.price) {
                return '(未配置价格)'
            }
            let price = this.code.price.toString()
            const dotIndex = price.indexOf('.')
            if (dotIndex == -1) {
                price = price + '.00'
            } else if (dotIndex === price.length - 2) {
                price = price + '0'
            }
            return price
        }
    }
}
</script>

<style lang="scss" scoped>
//产品信息
.mc-info {
    background-color: #fff;
    text-align: left;
}

.currency-symbol,
.currency-price {
    text-align: center;
    color: #f46d10;
    font-size: 28px;
}
</style>
