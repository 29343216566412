const API_PATH_PREFIX = ''

export function getData(url, arg = {}) {
    return fetchData(url, arg)
}

function fetchData(url, arg) {
    const realUrl = API_PATH_PREFIX + url
    return wrapFetchResult(
        fetch(
            realUrl,
            Object.assign(arg, {
                credentials: 'include'
            })
        ),
        realUrl
    )
}

function wrapFetchResult(promise, url) {
    return promise
        .then(res => {
            if (res.ok) {
                return res
            }
            return getResponse(res).then(err => {
                if (err.timestamp) {
                    err.timestamp = new Date(err.timestamp)
                }
                throw new ResponseError(url, res.status, wrapStatusText(res.status, res.statusText), err.message)
            })
        })
        .catch(err => {
            if (err instanceof TypeError) {
                const message = err.message && err.message.startsWith('net::') ? '网络错误' : err.message
                throw new ResponseError(url, null, err.message, message)
            }
            throw err
        })
}

export function getResponse(response) {
    const type = response.headers.get('Content-Type')
    if (!type || type.indexOf('plaintext') > -1) {
        return response.text()
    }
    if (type.indexOf('json') > -1) {
        return response.json()
    }
    throw new ResponseError(response.url, '-', 'unkonwn response type')
}

class ResponseError {
    constructor(url, status, statusText, message) {
        this.url = url
        this.status = status
        this.statusText = statusText
        this.message = message
    }
}

function wrapStatusText(status, statusText) {
    if (status >= 400 && status < 500) {
        return '错误的请求'
    }
    if (status >= 500 && status < 600) {
        return '服务器错误'
    }
    return statusText
}

export function getJson(url, arg = {}) {
    return getData(
        url,
        Object.assign(
            {
                headers: {
                    Accept: 'application/json'
                }
            },
            arg
        )
    ).then(res => res.json())
}

export function getText(url, arg = {}) {
    return getData(url, arg).then(res => res.text())
}

export function postData(url, arg = {}) {
    return fetchData(
        url,
        Object.assign(arg, {
            method: 'POST'
        })
    )
}

export function postJson(url, jsonData, arg = {}) {
    return postData(
        url,
        Object.assign(arg, {
            body: JSON.stringify(jsonData),
            headers: {
                'Content-Type': 'application/json'
            }
        })
    )
}

export function postFormData(url, data, arg = {}) {
    if (!(data instanceof FormData)) {
        const formData = new FormData()
        for (const key of Object.keys(data)) {
            formData.append(key, data[key])
        }
        data = formData
    }
    return postData(
        url,
        Object.assign(arg, {
            body: data,
            headers: {
                'Content-Type': 'form/form-data'
            }
        })
    )
}

export function putData(url, arg = {}) {
    return fetchData(
        url,
        Object.assign(arg, {
            method: 'PUT'
        })
    )
}

export function putJson(url, jsonData, arg = {}) {
    return putData(
        url,
        Object.assign(arg, {
            body: JSON.stringify(jsonData),
            headers: {
                'Content-Type': 'application/json'
            }
        })
    )
}

export function deleteData(url, arg = {}) {
    return fetchData(
        url,
        Object.assign(arg, {
            method: 'DELETE'
        })
    )
}
