<template>
    <div class="interpret-page">
        <InterpretWeb v-if="isWeb"></InterpretWeb>
        <InterpretMob v-if="!isWeb"></InterpretMob>
    </div>
</template>

<script>
import { getPcOrMob } from '../utils/utils'
import InterpretMob from '../components/interpret/interpret-mob.vue'
import InterpretWeb from '../components/interpret/interpret-web.vue'

export default {
    name: 'InterpretView',
    mounted() {
        setInterval(() => {
            this.isWeb = getPcOrMob()
        }, 100)
    },
    data() {
        return {
            isWeb: true,
        }
    },
    components: {
        InterpretMob, InterpretWeb
    },
};
</script>
<style lang="scss" scoped>
    .interpret-page{
        width: 100%;
    }
</style>
