<template>
    <div>
        <div class="header-class">
            <div class="header-top">
                <div class="hello-text">您好，欢迎来到国家工业互联网标识追溯防伪平台</div>
                <div class="login-register">
                    <a class="login" href="javaScript:0;">登录</a>
                    <a class="register" href="javaScript:0;">免费注册</a>
                </div>
            </div>
            <div class="header-menu">
                <el-menu :default-active="activeIndex" class="el-menu" mode="horizontal" @select="handleSelect"
                    :ellipsis="false" background-color="#B42620" text-color="#FFFFFF" active-text-color="#B42620">
                    <el-menu-item index="1">首页</el-menu-item>
                    <el-submenu index="2">
                        <template #title>国家标识解析追溯防伪平台</template>
                        <el-menu-item index="2-1">
                            <a class="anchor" href="#traceIn">进入</a>
                        </el-menu-item>
                        <el-menu-item index="2-2">
                            <a class="anchor" href="#traceIntro">功能介绍</a>
                        </el-menu-item>
                        <el-menu-item index="2-3">
                            <a class="anchor" href="#traceApp">应用场景</a>
                        </el-menu-item>
                        <el-menu-item index="2-4">
                            <a class="anchor" href="#traceAngle">案例展示</a>
                        </el-menu-item>
                    </el-submenu>
                    <el-menu-item index="3">政策解读</el-menu-item>
                </el-menu>
            </div>
        </div>
    </div>
</template>

<script lang="js">
export default {
    mounted() {
        this.getRoutersPath()
    },
    name: 'HeaderWeb',
    data() {
        return {
            activeIndex: '1',
        }
    },
    methods: {
        handleSelect(index) {
            let splitIndex = index.split('-')[0];
            switch (splitIndex * 1) {
                case 1:
                    this.$router.push('/')
                    break;
                case 2:
                    this.$router.push('/traceManage')
                    break;
                case 3:
                    this.$router.push('/interpret')
                    break;
            }
        },
        getRoutersPath() {
            switch (this.$router.currentRoute.path) {
                case '/interpret':
                    this.activeIndex = '3'
                    break
                case '/traceManage':
                    this.activeIndex = '2'
                    break;
                case '/':
                    this.activeIndex = '1'
                    break
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.header-class {
    min-width: 1080px;
    width: 100%;

    .header-top {
        height: 38px;
        background-color: #EBEBEB;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .hello-text {
            margin-left: 180px;
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #151515;
            line-height: 20px;
        }

        .login-register {
            margin-right: 180px;

            .login {
                width: 28px;
                height: 20px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #B42620;
                line-height: 20px;
            }

            .register {
                width: 28px;
                height: 20px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #B42620;
                line-height: 20px;
                margin-left: 40px;
            }
        }
    }

    .header-menu {
        height: 72px;
        background: #B42620;
        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1100);
        display: flex;
        align-items: center;
        justify-content: right;
        font-size: 17px;
        font-weight: 600;

        .el-menu-item {
            height: 100%;
            line-height: 72px;
            padding: 0px 40px;
            border: none;
        }

        .el-submenu {
            height: 100%;
            border-bottom: none;
        }

        ::v-deep .el-menu--horizontal>.el-submenu .el-submenu__title {
            border-bottom: none !important;
            height: 72px !important;
            line-height: 72px !important;
        }

        .el-menu {
            margin-right: 140px;
            height: 100%;
        }

        ::v-deep .is-active {
            background-color: #FFFFFF;
            color: #B42620;
            border-bottom: none;
        }
        ::v-deep .el-menu--horizontal>.is-active .el-submenu__title {
            border-bottom: none !important;
            height: 72px !important;
            line-height: 72px !important;
            background-color: #FFFFFF !important;
            color: #B42620 !important;
            border-bottom: none !important;
        }

    }
}
</style>
