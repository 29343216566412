<template>
    <el-carousel
        class="hoverStyle"
        v-if="imageLength"
        :height="height"
        style="background-color: #ffffff"
        arrow="never"
        trigger="click"
    >
        <el-carousel-item v-for="(src, idx) in item.value.images" :key="idx">
            <a :href="getCarouselImgUrl">
                <img :style="{ width: '100%', height }" :src="imageSrc(src)" />
            </a>
        </el-carousel-item>
    </el-carousel>
    <div
        class="hoverStyle"
        v-else
        :style="{ height }"
        style="display: flex; justify-content: center; align-items: center; color: #888888; background-color: #ffffff"
    >
        <p>{{ '(无图片)' }}</p>
    </div>
</template>

<script>
export default {
    props: {
        item: Object
    },
    computed: {
        imageLength() {
            return this.item.value.images.length
        },
        height() {
            return this.item.value.height || '200px'
        },
        getCarouselImgUrl() {
            return this.item.value.url
        }
    },
    methods: {
        imageSrc(src) {
            return src + '?x-image-process=style/width-1024'
        }
    }
}
</script>

<style scoped>
.hoverStyle:hover {
    border: 1px solid #20a0ff;
}

::v-deep .el-carousel__button {
    height: 3px !important;
    width: 3px !important;
    border-radius: 4px !important;
}
::v-deep .is-active > .el-carousel__button {
    height: 3px !important;
    width: 10px !important;
}
</style>
